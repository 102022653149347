import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { singlWallet, wallets } from '../../actions/wallets';
import { useParams } from 'react-router-dom';
import TopBar from './../../components/topbar';
import { SideBar } from '../../components/sidebar';
import { QRCode } from 'react-qrcode-logo';
import Typography from '@mui/material/Typography';
import SwitchesNetwork from '../../components/swtitcher-network';



const Receive = ({ customer, wallets, singlWallet }) => {
    const { id } = useParams();
    const [copied, setCopied] = useState(false);
    const [isNetworkTron, setIsNetworkTron] = useState(true);

    const handleCopy = () => {
        navigator.clipboard.writeText(
            wallets?.singlWalletData?.networks?.length === 2 ?
                wallets?.singlWalletData?.networks?.[isNetworkTron ? 0 : 1]?.hash :
                wallets?.singlWalletData?.networks?.length === 1 ?
                    wallets?.singlWalletData?.networks?.[0]?.hash :
                    wallets?.singlWalletData?.hash
        ).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Убираем сообщение через 2 секунды
        });
    };

    const handleChangeSwitcher = (event) => {
        setIsNetworkTron(event.target.checked);
    };

    useEffect(() => {
        singlWallet(id)
    }, [])

    if (!wallets.loading) {
        return (
            <div className='loader-block'>
                <div class="w-auto m-y-3 text-xs-center">
                    <div class="d-inline-flex">
                        <div class="tw-loader tw-loader--sm tw-loader--visible" data-testid="loader"></div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <>

                <div id="__next">
                    <div>
                        <div class="np-theme-personal">
                            <div class="np-theme-personal">
                                <div style={{ minHeight: '100vh' }}>
                                    <TopBar size='small' />



                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div class="p-x-2 p-y-5">
                                            <h2 class="text-xs-center m-b-5">

                                                Receive money in {wallets?.singlWalletData?.wallet_type?.code}
                                            </h2>
                                            <div style={{ maxWidth: '400px', margin: 'auto' }}>
                                                <div class="form-group" data-testid="currency-select">
                                                    <div style={{ padding: '30px 0 0 0', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(245 245 245)', borderRadius: '20px' }}>
                                                        { 
                                                             wallets?.singlWalletData?.networks?.length ?
                                                                 wallets?.singlWalletData?.networks?.length === 2 ? 
                                                                 (
                                                                        <>
                                                                            <>
                                                                                <QRCode
                                                                                    value={wallets?.singlWalletData?.networks?.[isNetworkTron ? 0 : 1]?.hash}
                                                                                    logoImage='/new.png'
                                                                                    size={250}
                                                                                    fgColor='#163300'
                                                                                    qrStyle='fluid'
                                                                                    bgColor={'rgb(245 245 245)'}
                                                                                    logoPadding={1}
                                                                                    logoWidth={60}
                                                                                    logoHeight={60}
                                                                                    logoPaddingStyle='circle'
                                                                                    eyeRadius={
                                                                                        [
                                                                                            ['50', '50', '50', '50'],
                                                                                            ['50', '50', '50', '50'],
                                                                                            ['50', '50', '50', '50'],

                                                                                        ]
                                                                                    }
                                                                                />
                                                                                <Typography variant="subtitle1" gutterBottom style={{ wordBreak: 'break-all', maxWidth: '250px', textAlign: 'center' }}>
                                                                                    {wallets?.singlWalletData?.networks?.[isNetworkTron ? 0 : 1]?.hash}
                                                                                </Typography>
                                                                            </>
                                                                            <SwitchesNetwork isNetworkTron={isNetworkTron}
                                                                                setIsNetworkTron={handleChangeSwitcher} network1={wallets?.singlWalletData?.networks?.[0]} network2={wallets?.singlWalletData?.networks?.[1]} />
                                                                        </>
                                                                ) : wallets?.singlWalletData?.networks?.length === 1 ? (
                                                            <>
                                                                <QRCode
                                                                    value={wallets?.singlWalletData?.networks?.[0]?.hash}
                                                                    logoImage='/new.png'
                                                                    size={250}
                                                                    fgColor='#163300'
                                                                    qrStyle='fluid'
                                                                    bgColor={'rgb(245 245 245)'}
                                                                    logoPadding={1}
                                                                    logoWidth={60}
                                                                    logoHeight={60}
                                                                    logoPaddingStyle='circle'
                                                                    eyeRadius={
                                                                        [
                                                                            ['50', '50', '50', '50'],
                                                                            ['50', '50', '50', '50'],
                                                                            ['50', '50', '50', '50'],

                                                                        ]
                                                                    }
                                                                />
                                                                <Typography variant="subtitle1" gutterBottom style={{ wordBreak: 'break-all', maxWidth: '250px', textAlign: 'center' }}>
                                                                    {wallets?.singlWalletData?.networks?.[0]?.hash}
                                                                </Typography>
                                                            </> 
                                                        ) : (<></>) : (<></>)
                                                        }
                                                        {wallets?.singlWalletData?.hash && (
                                                            <>
                                                                <QRCode
                                                                    value={wallets?.singlWalletData?.hash}
                                                                    logoImage='/new.png'
                                                                    size={250}
                                                                    fgColor='#163300'
                                                                    qrStyle='fluid'
                                                                    bgColor={'rgb(245 245 245)'}
                                                                    logoPadding={1}
                                                                    logoWidth={60}
                                                                    logoHeight={60}
                                                                    logoPaddingStyle='circle'
                                                                    eyeRadius={
                                                                        [
                                                                            ['50', '50', '50', '50'],
                                                                            ['50', '50', '50', '50'],
                                                                            ['50', '50', '50', '50'],

                                                                        ]
                                                                    }
                                                                />
                                                                <Typography variant="subtitle1" gutterBottom style={{ wordBreak: 'break-all', maxWidth: '250px', textAlign: 'center' }}>
                                                                    {wallets?.singlWalletData?.hash}
                                                                </Typography>
                                                            </>
                                                        )}


                                                        {/* <Autocomplete
                                                        className='btn btn-md np-btn np-btn-md btn-block np-btn-block np-dropdown-toggle np-text-body-large'
                                                        disablePortal
                                                        onChange={handleWalletChange} 
                                                        options={wallets.walletsTypesAll}
                                                        getOptionLabel={(option) => option.code}
                                                        sx={{ width: '100%', borderRadius: '10px', margin: 0, padding: 0, }}
                                                        renderOption={(props, option) => (
                                                            <li {...props}>
                                                              <Avatar
                                                                src={option.img}
                                                                alt={option.code}
                                                                sx={{ marginRight: 1 }}
                                                              />
                                                              {option.code}
                                                            </li>
                                                          )}
                                                        renderInput={(params) => <TextField {...params} label="Choose currency..." />}
                                                    /> */}

                                                        {/* <div class="np-select btn-block btn-group">
                                                <Autocomplete
                                                        className='np-select btn-block btn-group'
                                                        disablePortal
                                                        options={options}
                                                        sx={{ width: 300 }}
                                                        renderInput={(params) => <TextField {...params} label="Choose currency..." />}
                                                    /> */}
                                                        {/* <button class="btn btn-md np-btn np-btn-md btn-block np-btn-block np-dropdown-toggle np-text-body-large" id=":r0:" aria-controls=":r0:-listbox" aria-expanded="false" aria-disabled="false" type="button" aria-live="off" aria-busy="false">
                                                        <span class="form-control-placeholder">Choose currency...</span>
                                                        <span class="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron" aria-hidden="true" role="none" data-testid="chevron-up-icon">
                                                            <svg width="16" height="16" fill="currentColor" focusable="false" viewBox="0 0 24 24">
                                                                <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z">
                                                                </path>
                                                            </svg>
                                                        </span>
                                                    </button> */}
                                                        {/* </div> */}
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px 0 ' }}>
                                                        <div class="css-hugk3o">
                                                            <div class="css-rz0j7z">
                                                                <label
                                                                    class="np-circular-btn primary accent"
                                                                    onClick={handleCopy}
                                                                >
                                                                    <input
                                                                        type="button" aria-label="Add"
                                                                        class="btn np-btn btn-accent btn-priority-1" /><span
                                                                            class="tw-icon tw-icon-plus " aria-hidden="true"
                                                                            role="none" data-testid="plus-icon">
                                                                        <svg width="24"
                                                                            height="24" fill="currentColor" focusable="false" viewBox="0 0 24 24">
                                                                            <path fill-rule="evenodd"
                                                                                d="M14.571 10.284V12c0 2.828-2.314 5.143-5.143 5.143-2.828 0-5.142-2.315-5.142-5.143V6.856c0-2.829 2.314-5.143 5.143-5.143 2.485 0 4.628 1.714 5.1 4.114l-1.672.343c-.3-1.543-1.8-2.743-3.428-2.743a3.439 3.439 0 0 0-3.43 3.429v5.143a3.439 3.439 0 0 0 3.43 3.428A3.439 3.439 0 0 0 12.857 12v-1.715h1.714ZM9.428 12c0-2.828 2.315-5.143 5.143-5.143 2.829 0 5.143 2.315 5.143 5.143v5.143c0 2.829-2.314 5.143-5.143 5.143-2.485 0-4.671-1.714-5.143-4.114l1.715-.343c.3 1.586 1.757 2.743 3.428 2.743A3.439 3.439 0 0 0 18 17.143V12a3.439 3.439 0 0 0-3.429-3.428A3.439 3.439 0 0 0 11.143 12v1.715H9.428V12Z"
                                                                                clip-rule="evenodd"></path>
                                                                        </svg>

                                                                    </span><span
                                                                        class="np-text-body-default-bold np-circular-btn__label">Copy</span></label>
                                                            </div>

                                                            {/* <div class="css-rz0j7z">
                                                            <label class="np-circular-btn secondary accent">
                                                                <input type="button" aria-label="Send" class="btn np-btn btn-accent btn-priority-2" />
                                                                <span class="tw-icon tw-icon-send " aria-hidden="true" role="none" data-testid="send-icon">
                                                                    <svg width="24" height="24" fill="currentColor" focusable="false" viewBox="0 0 24 24">
                                                                    <path
                                                                        d="M21.343 10.543 12.77 1.972a.829.829 0 0 0-1.2 0L3 10.543l1.2 1.2 7.114-7.114v17.657h1.715V4.63l7.114 7.114 1.2-1.2Z">
                                                                    </path>
                                                                </svg>
                                                            </span>
                                                            <span class="np-text-body-default-bold np-circular-btn__label">Share</span>
                                                            </label>
                                                        </div> */}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-xs-center m-b-4" data-testid="message">You can open balances in 40+ currencies.</div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};


const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ singlWallet }, dispatch)
);

export default widthConnect(Receive);