import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CryptoChart } from '../../components/chart';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';


const TestCharts = ({ }) => {
    const [chartList, setChartList] = useState([]);
    const [chartListCryptoChart, setChartListCryptoChart] = useState('[]');
    return (
        <div id="__next">
            <CryptoChart chartsList={{}} wallets={{}} charts={{
                list: JSON.parse(chartListCryptoChart) || []
            }} />


            <TextField
                id="outlined-multiline-static"
                label="Multiline"
                multiline
                rows={4}
                value={chartList}
                onChange={(e) => setChartList(e.target.value)}
                defaultValue=""
            />

            <Button onClick={(e) => setChartListCryptoChart(chartList)} >
                Vpered
            </Button>


        </div>
    );
};


const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({}, dispatch)
);

export default widthConnect(TestCharts);