import { useEffect } from 'react';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const Item = ({ item }) => {


    return (
        <>
            <a

                aria-label="USD balance. 3,24 USD. Account details ending in 1 6 3 3 6."
                class="BalanceDetailsCard_balanceCard"
                // href= {`/${item.wallet_type.code}`}
                href={`/balances/${item.id}`}


            // style="opacity:1"
            >
                <div className={`percentBlock ${item.percent === 0 ? 'hide' : ''}`} style={{color: item.percent < 0 ? 'red' : 'green'}}>
                    { item.percent < 0 ?(<TrendingDownIcon />):(<TrendingUpIcon/>) } <span>{item.percent.toFixed(2)}%</span>
                </div>
                <div class="d-flex align-items-center">
                    <div aria-hidden="true" class="BalanceDetailsCard_balanceCardAvatar">
                        <div
                            class="tw-avatar tw-avatar--48 tw-avatar--thumbnail">
                            <div class="tw-avatar__content">
                                <img
                                    class="wds-flag wds-flag-usd"
                                    src={item.wallet_type.img}
                                    alt="" width="64"
                                    height="64" />
                            </div>
                        </div>
                    </div>
                    <h4 class="np-text-title-body BalanceDetailsCard_balanceTextContentSecondary BalanceDetailsCard_balanceCardText BalanceDetailsCard_balanceTextContentSecondaryOverflow">
                        {item.wallet_type.code}
                    </h4>
                </div>
                <div class="BalanceDetailsCard_balanceCardText">
                    {/* <div
                    class="np-text-body-default d-flex align-items-center">
                    <span
                        class="tw-icon tw-icon-bank m-r-1 BalanceDetailsCard_balanceTextContentSecondary"
                        aria-hidden="true" role="none"
                        data-testid="bank-icon">
                        <svg
                            width="16" height="16"
                            fill="currentColor"
                            focusable="false"
                            viewBox="0 0 24 24">
                            <path fill-rule="evenodd"
                                d="M12.905 2.658a1.304 1.304 0 0 0-1.373-.015L2.42 8.156a.87.87 0 0 0 .45 1.614h1.304v10.002h-1.74v1.739h19.131v-1.74h-1.739v-10h1.304V9.77a.869.869 0 0 0 .466-1.604l-8.69-5.508Zm5.23 5.373-5.933-3.76-6.216 3.76h12.149Zm-5.266 1.74h5.218v10H12.87v-10Zm-6.956 0v10h5.217v-10H5.913Z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </span>
                    <span className="BalanceDetailsCard_balanceTextContentSecondary BalanceDetailsCard_balanceTextContentSecondaryOverflow">
                        ··
                        16336
                    </span>
                </div> */}
                    <div
                        // style="font-size:1.375rem;letter-spacing:normal;overflow-wrap:normal;white-space:nowrap"
                        class="m-b-0 title-3 BalanceDetailsCard_balanceCardValue">
                        <span class="parseMarkupTags_sensitive">{item.balance} {item.wallet_type.code}</span>
                    </div>
                    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between' }}>
                        <span className="BalanceDetailsCard_balanceTextContentSecondary BalanceDetailsCard_balanceTextContentSecondaryOverflow">{item.balance_usd} $</span>
                        <span>{item.hash_hide}</span>
                    </div>
                </div>
            </a >
        </>
    );
};

export default Item;









