import React, { useEffect, useRef, useState } from 'react';
import { TextField, MenuItem, InputAdornment, Box, InputLabel, Input, FormHelperText } from '@mui/material';
import { Select, FormControl } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { walletsTypesAll } from '../../actions/wallets';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Avatar from '@mui/material/Avatar';
import { Wallet } from '@mui/icons-material';
import { transactionsExchange } from '../../actions/transactions';
import { useNavigate } from 'react-router-dom';
import { wallets } from '../../actions/wallets';
import { transactions, clearError } from '../../actions/transactions';
import TopBar from '../../components/topbar';





const Convert = ({ walletsTypesAll, clearError, wallets, transactions, walletsList, transactionsExchange, transactionsList }) => {
    const navigate = useNavigate();
    const [walletQuery, setWalletQuery] = useState({
        wallet_from_id: 2,
        wallet_to_id: 6,
        wallet_from_ammount: 0,
        wallet_to_ammount: 0,
        isMax: false,
    })

    if (transactions?.exchange?.length > 0) {
        walletsList()
        transactionsList()
        navigate('/home');

    }

    const [isDisabled, setIsDisabled] = useState(true);

    const [amount1, setAmount1] = useState(0);
    const [selectedCurrency1, setSelectedCurrency1] = useState('');

    const handleAmountChange1 = (event) => {
        if (isDisabled) {
            setIsDisabled(false)
        }
        if (transactions?.error) {
            clearError()
        }
        if (wallets?.walletsTypesAll?.length > 0) {
            let summ = +event.target.value * +wallets.walletsTypesAll.find(currency => currency.code === selectedCurrency1).price / +wallets.walletsTypesAll.find(currency => currency.code === selectedCurrency2).price
            setAmount1(event.target.value);
            setAmount2(summ);
            setWalletQuery({
                wallet_from_id: wallets.walletsTypesAll.find(currency => currency.code === selectedCurrency1).id,
                wallet_to_id: wallets.walletsTypesAll.find(currency => currency.code === selectedCurrency2).id,
                wallet_from_ammount: +event.target.value,
                wallet_to_ammount: 0,
                isMax: false,
            })
        }
    };

    const handleSubmit = () => {
        setIsDisabled(true)
        transactionsExchange(walletQuery)
    };

    const handleCurrencyChange1 = (event) => {
        
        if (event.target.value == selectedCurrency2) {
            let CurrencyTransfer = selectedCurrency1

            setWalletQuery({
                ...walletQuery,
                wallet_from_id: wallets.walletsTypesAll.find(currency => currency.code === event.target.value).id,
                wallet_to_id: wallets.walletsTypesAll.find(currency => currency.code === CurrencyTransfer).id,
                isMax: false,
    
            })

            setSelectedCurrency2(CurrencyTransfer);
            setSelectedCurrency1(event.target.value);
            let summ = +amount1 * +wallets.walletsTypesAll.find(currency => currency.code === event.target.value).price / +wallets.walletsTypesAll.find(currency => currency.code === CurrencyTransfer).price
            setAmount2(summ);
        } else {

            setWalletQuery({
                ...walletQuery,
                wallet_from_id: wallets.walletsTypesAll.find(currency => currency.code === event.target.value).id,
                isMax: false,
    
            })

            setSelectedCurrency1(event.target.value);
            let summ = +amount1 * +wallets.walletsTypesAll.find(currency => currency.code === selectedCurrency2).price / +wallets.walletsTypesAll.find(currency => currency.code === event.target.value).price
            setAmount2(summ);
        }

        

    };

    const [amount2, setAmount2] = useState(0);
    const [selectedCurrency2, setSelectedCurrency2] = useState('USDT');
    const handleAmountChange2 = (event) => {
       
        if (isDisabled) {
            setIsDisabled(false)
        }
        if (transactions?.error) {
            clearError()
        }
        let summ = +event.target.value * +wallets.walletsTypesAll.find(currency => currency.code === selectedCurrency2).price / +wallets.walletsTypesAll.find(currency => currency.code === selectedCurrency1).price
        setAmount2(event.target.value);
        setAmount1(summ);

        setWalletQuery({
            wallet_from_id: wallets.walletsTypesAll.find(currency => currency.code === selectedCurrency1).id,
            wallet_to_id: wallets.walletsTypesAll.find(currency => currency.code === selectedCurrency2).id,
            wallet_from_ammount: 0,
            wallet_to_ammount: +event.target.value,
            isMax: false,
        })


    };
    const handleCurrencyChange2 = (event) => {
        
        if (event.target.value == selectedCurrency1) {
            let CurrencyTransfer = selectedCurrency2

            setWalletQuery({
                ...walletQuery,
                wallet_from_id: wallets.walletsTypesAll.find(currency => currency.code === CurrencyTransfer).id,
                wallet_to_id: wallets.walletsTypesAll.find(currency => currency.code === event.target.value).id,
                isMax: false,
            })

            setSelectedCurrency1(CurrencyTransfer);
            setSelectedCurrency2(event.target.value);
            let summ = +amount1 * +wallets.walletsTypesAll.find(currency => currency.code === CurrencyTransfer).price / +wallets.walletsTypesAll.find(currency => currency.code === event.target.value).price
            setAmount2(summ);

        } else {
            setSelectedCurrency2(event.target.value);
            setWalletQuery({
                ...walletQuery,
                wallet_to_id: wallets.walletsTypesAll.find(currency => currency.code === event.target.value).id,
                isMax: false,
            })
            let summ = +amount1 * +wallets.walletsTypesAll.find(currency => currency.code === selectedCurrency1).price / +wallets.walletsTypesAll.find(currency => currency.code === event.target.value).price
            setAmount2(summ);
        }


    };

    const handleClickMax = () => {
        setIsDisabled(false)
        setWalletQuery({
            ...walletQuery,
            isMax: true,

        })
        setAmount1(parseFloat((wallets.wallets.find(currency => currency.wallet_type.code === selectedCurrency1).balance || '0').replaceAll(/,/g, '')))
        let summ = +parseFloat((wallets.wallets.find(currency => currency.wallet_type.code === selectedCurrency1).balance || '0').replaceAll(/,/g, '')) * +wallets.walletsTypesAll.find(currency => currency.code === selectedCurrency1).price / +wallets.walletsTypesAll.find(currency => currency.code === selectedCurrency2).price
        setAmount2(summ);


    };

    useEffect(() => {
        walletsTypesAll()
    }, [])

    useEffect(() => {
        console.log(walletQuery);
    }, [walletQuery])



    useEffect(() => {
        if (wallets?.walletsTypesAll?.length > 0 && wallets?.wallets) {

            setSelectedCurrency1(wallets?.wallets[1].wallet_type.code)

            // setAmount2(wallets.walletsTypesAll.find(currency => currency.code === 'BTC').price)
        }
    }, [wallets.walletsTypesAll, wallets.wallets])


    if (!wallets.loading) {
        return (
            <div className='loader-block'>
                <div class="w-auto m-y-3 text-xs-center">
                    <div class="d-inline-flex">
                        <div class="tw-loader tw-loader--sm tw-loader--visible" data-testid="loader"></div>
                    </div>
                </div>
            </div>
        )
    } else {

        return (
            <>
                <div id="__next">
                    <div>
                        <div class="np-theme-personal">
                            <div class="np-theme-personal">
                                <div
                                // style="min-height: 100vh;"
                                >
                                    <TopBar size='small' />
                                    <div className='centrBlock'>
                                        <div class="p-x-2 p-y-5">
                                            <h2 class="text-xs-center m-b-5">How much do you want to convert?</h2>
                                            <div
                                            // style="max-width: 500px; margin: auto;"
                                            >
                                                <div class="m-t-2">
                                                    <div data-testid="money-input-source" class="has-info">
                                                        <div class="np-field form-group d-block has-info m-b-0">

                                                            <label id=":r0:"
                                                                for="tw-calculator-source"
                                                                class="np-label d-flex flex-column np-text-body-default-bold text-primary m-b-0">Convert</label>

                                                            <FormControl sx={{ width: '100%' }} variant="outlined" >
                                                                <OutlinedInput
                                                                    error={transactions && transactions.error}
                                                                    className='form-control np-form-control np-form-control--size-auto np-input np-input--shape-rectangle'
                                                                    style={{ padding: 0 }}
                                                                    id="outlined-adornment-weight"
                                                                    placeholder='0'
                                                                    onChange={handleAmountChange1}
                                                                    error={transactions.error}
                                                                    value={amount1}
                                                                    endAdornment={

                                                                        <InputAdornment position="end">
                                                                            <InputAdornment position="end">
                                                                                <span onClick={handleClickMax} style={{ cursor: 'pointer' }}>
                                                                                    Max
                                                                                </span>
                                                                            </InputAdornment>

                                                                            {/* <Select2Component options={options} onChange={handleSelectChange} /> */}
                                                                            <Select
                                                                                className='NOBORDER'
                                                                                labelId="currency-label"
                                                                                value={selectedCurrency1}
                                                                                onChange={handleCurrencyChange1}
                                                                                label="Currency"
                                                                                sx={{
                                                                                    width: '150px', height: '54px',
                                                                                    '.MuiOutlinedInput-root': {
                                                                                        '& fieldset': {
                                                                                            borderColor: 'red', // Обычное состояние рамки
                                                                                        },
                                                                                        '&:hover fieldset': {
                                                                                            borderColor: 'red', // Цвет рамки при наведении
                                                                                        },
                                                                                        '&.Mui-focused fieldset': {
                                                                                            borderColor: 'red', // Цвет рамки при фокусе
                                                                                        },
                                                                                        '&.Mui-error fieldset': {
                                                                                            borderColor: 'red', // Цвет рамки при ошибке
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            // MuiOutlinedInput-notchedOutline css-1d3z3hw-MuiOutlinedInput-notchedOutline

                                                                            >
                                                                                {wallets?.wallets?.map((currency) => (
                                                                                    <MenuItem
                                                                                        key={currency.wallet_type.code}
                                                                                        value={currency.wallet_type.code}
                                                                                    >
                                                                                        {/* <FlagIcon code={currency.flag} size={24} style={{ marginRight: 8 }} /> */}
                                                                                        <Avatar
                                                                                            src={currency.wallet_type.img}
                                                                                            alt={currency.wallet_type.code}
                                                                                            sx={{ marginRight: 1, width: '20px', height: '20px' }} // Добавляем отступ
                                                                                        />
                                                                                        {currency.wallet_type.code}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </InputAdornment>}

                                                                    aria-describedby="outlined-weight-helper-text"
                                                                    inputProps={{
                                                                        'aria-label': 'weight',
                                                                    }}
                                                                />
                                                                <label htmlFor="">
                                                                    {!!transactions.error && (
                                                                        <FormHelperText error id="accountId-error">
                                                                            {transactions.error}
                                                                        </FormHelperText>
                                                                    )}
                                                                </label>
                                                            </FormControl>


                                                        </div>

                                                    </div>
                                                </div>

                                                <br />

                                                <div class="m-b-2">
                                                    <div data-testid="money-input-target" class="">
                                                        <div class="np-field form-group d-block has-info m-b-0">
                                                            <label id=":r9:"
                                                                for="tw-calculator-target"
                                                                class="np-label d-flex flex-column np-text-body-default-bold text-primary m-b-0">Adding
                                                                to</label>
                                                            <FormControl sx={{ width: '100%' }} variant="outlined" >
                                                                <OutlinedInput
                                                                    className='form-control np-form-control np-form-control--size-auto np-input np-input--shape-rectangle'
                                                                    style={{ padding: 0 }}
                                                                    id="outlined-adornment-weight"
                                                                    placeholder='0'
                                                                    onChange={handleAmountChange2}
                                                                    value={amount2}
                                                                    endAdornment={<InputAdornment position="end" >
                                                                        <Select
                                                                            className='NOBORDER'

                                                                            labelId="currency-label"
                                                                            value={selectedCurrency2}
                                                                            onChange={handleCurrencyChange2}
                                                                            label="Currency"
                                                                            sx={{
                                                                                width: '150px', height: '54px',
                                                                                '.MuiOutlinedInput-root': {
                                                                                    '& fieldset': {
                                                                                        borderColor: 'red', // Обычное состояние рамки
                                                                                    },
                                                                                    '&:hover fieldset': {
                                                                                        borderColor: 'red', // Цвет рамки при наведении
                                                                                    },
                                                                                    '&.Mui-focused fieldset': {
                                                                                        borderColor: 'red', // Цвет рамки при фокусе
                                                                                    },
                                                                                    '&.Mui-error fieldset': {
                                                                                        borderColor: 'red', // Цвет рамки при ошибке
                                                                                    },
                                                                                },
                                                                            }}
                                                                        >
                                                                            {wallets?.walletsTypesAll?.map((currency) => (
                                                                                <MenuItem
                                                                                    key={currency.code}
                                                                                    value={currency.code}
                                                                                >
                                                                                    {/* <FlagIcon code={currency.flag} size={24} style={{ marginRight: 8 }} /> */}
                                                                                    <Avatar
                                                                                        src={currency.img}
                                                                                        alt={currency.code}
                                                                                        sx={{ marginRight: 1, width: '20px', height: '20px' }} // Добавляем отступ
                                                                                    />
                                                                                    {currency.code}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>

                                                                    </InputAdornment>}
                                                                    aria-describedby="outlined-weight-helper-text"
                                                                    inputProps={{
                                                                        'aria-label': 'weight',
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="m-t-2"></div>
                                                <div class="m-t-5">
                                                    <button
                                                        class="btn btn-md np-btn np-btn-md btn-block np-btn-block btn-accent btn-priority-1"
                                                        aria-disabled="false" type="button" aria-live="off"
                                                        aria-busy="false"
                                                        disabled={isDisabled}
                                                        onClick={handleSubmit}
                                                    >
                                                        Continue
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}


const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ clearError, walletsTypesAll, transactionsExchange, walletsList: wallets, transactionsList: transactions }, dispatch)
);

export default widthConnect(Convert);