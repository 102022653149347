import htmlContent from './wise-com/index.jsx';
import { useState, useEffect } from 'react';


const Landing = () => {
  const [loading, setLoading] = useState(true); // По умолчанию состояние загрузки

  useEffect(() => {
    // Устанавливаем таймер на 3 секунды (3000 мс)
    const timer = setTimeout(() => {
      setLoading(false); // После 3 секунд скрываем loader
    }, 2000);

    // Очищаем таймер при размонтировании компонента
    return () => clearTimeout(timer);
  }, []);


  return (<>
    {
      loading && (
        <div className='loader-block'>
          <div className="w-auto m-y-3 text-xs-center">
            <div className="d-inline-flex">
              <div className="tw-loader tw-loader--sm tw-loader--visible" data-testid="loader"></div>
            </div>
          </div>
        </div>
        ) 
    }
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />

  </>
  );
}

export default Landing;