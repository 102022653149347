import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { singlWallet } from '../../actions/wallets';
import { useParams } from 'react-router-dom';
import TopBar from './../../components/topbar';
import { SideBar } from '../../components/sidebar';
import { transactionsSingle } from './../../actions/transactions';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';






const Referal = ({customer }) => {
    const [isOpenBurger, setIsOpen] = useState(false);
    const toggleClass = () => {
      setIsOpen(!isOpenBurger);
    };



    // if (!customer.loading) {
    if (false) {

        return (
            <div className='loader-block'>
                <div class="w-auto m-y-3 text-xs-center">
                    <div class="d-inline-flex">
                        <div class="tw-loader tw-loader--sm tw-loader--visible" data-testid="loader"></div>
                    </div>
                </div>
            </div>
        )
    } else {

        return (
            <div id="__next">
                <div class="np-theme-personal">
                    <div class="page-layout">
                        <div class="column-layout">
                            <SideBar toggleClass={toggleClass} isOpenBurger={isOpenBurger} />

                            <div class="column-layout-main">
                                <TopBar customer={customer} toggleClass={toggleClass} />
                                <div id="main" class="container-content">
                                    <div class="invite-page-container">
                                        <div>
                                            <div class="np-theme-personal np-theme-personal--forest-green invite-hero-container">
                                                <div class="invite-hero-info">
                                                    <h1 class="np-text-display-large text-primary invite-hero-title">Share Wise and
                                                        earn 500 WISE</h1>
                                                    <div class="np-text-body-large hero-subtitle">Share Wise with 3 friends and earn
                                                        500 WISE for yourself. Your friends get 500 WISE.</div>
                                                </div>

                                                <div class="invite-hero-animation">
                                                    <div class="invite-animation">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="invite-step-container">
                                                <div class="invite-step-illustration-container"><img
                                                    class="invite-step-illustration"
                                                    src="https://wise.com/public-resources/assets/referrals/business-landing-page/host_action_illustration.png"
                                                    alt="host action illustration" /></div>
                                                <div class="invite-step-info-container false">
                                                    <div class="invite-step-info-content">
                                                        <h1 class="np-text-display-small text-primary">Invite 3 friends</h1>
                                                        <div class="np-text-body-large m-t-2">All 3 friends get a free 500 WISE.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="invite-step-container">
                                                <div class="invite-step-info-container invite-step-info-inverted">
                                                    <div class="invite-step-info-content">
                                                        <h1 class="np-text-display-small text-primary">They get a free WISE</h1>
                                                        <div class="np-text-body-large m-t-2"></div>
                                                    </div>
                                                </div>
                                                <div class="invite-step-illustration-container"><img
                                                    class="invite-step-illustration"
                                                    src="https://wise.com/public-resources/assets/referrals/business-landing-page/free_transfer_illustration.png"
                                                    alt="free transfer illustration" /></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="invite-step-container">
                                                <div class="invite-step-illustration-container"><img
                                                    class="invite-step-illustration"
                                                    src="https://wise.com/public-resources/assets/referrals/business-landing-page/monetary_illustration.png"
                                                    alt="monetary illustration" /></div>
                                                <div class="invite-step-info-container false">
                                                    <div class="invite-step-info-content">
                                                        <h1 class="np-text-display-small text-primary">You earn 500 WISE</h1>
                                                        <div class="np-text-body-large m-t-2">Once all 3 friends make a transfer
                                                            over 100 USD in another currency, you can claim your reward.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};


const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ }, dispatch)
);

export default widthConnect(Referal);