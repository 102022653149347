import { useEffect } from 'react';

const ResetPasswordPage = () => {
  

    return (
      <>
        <h1>ResetPassword</h1>
      </>
    );
};

export default ResetPasswordPage;
