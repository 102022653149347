import {

    LAUNCHPOOLS_REQ_START,
    LAUNCHPOOLS_REQ_SUCCESS,
    LAUNCHPOOLS_REQ_FAIL,
   } from '../actions/launchpools';

const initialState = {
    list: null,
    loading: false,
    error: null,
};

const launchpools = (state = initialState, action) => {
    switch (action.type) {
        case LAUNCHPOOLS_REQ_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LAUNCHPOOLS_REQ_SUCCESS:
            return {
                ...state,
                list: action.payload,
                loading: false,
            };
        case LAUNCHPOOLS_REQ_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default launchpools;





