import { transactionsApi, transactionsSingleApi, transactionsExchangeApi, transactionsSendApi, transactionsFreezeApi } from '../api/transactionsApi';

export const TRANSACTIONS_REQ = 'TRANSACTIONS_REQ';
export const TRANSACTIONS_EXCHANGE_REQ = 'TRANSACTIONS_EXCHANGE_REQ';
export const SINGLE_TRANSACTIONS_REQ = 'SINGLE_TRANSACTIONS_REQ';
export const TRANSACTIONS_REQ_FAIL = 'TRANSACTIONS_REQ_FAIL';
export const SEND_TRANSACTIONS_REQ = 'SEND_TRANSACTIONS_REQ';
export const SEND_TRANSACTIONS_REQ_FAIL = 'SEND_TRANSACTIONS_REQ_FAIL';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const FREEZE_REQ_START = 'FREEZE_REQ_START';
export const FREEZE_REQ_SUCCESS = 'FREEZE_REQ_SUCCESS';
export const FREEZE_REQ_FAIL = 'FREEZE_REQ_FAIL';


const freezeStart = () => {
    return { type: FREEZE_REQ_START };
};

const freezeSuccess = (data) => {
    return { type: FREEZE_REQ_SUCCESS, payload: data };
};

const freezeFail = (error) => {
    return { type: FREEZE_REQ_FAIL, payload: error };
};


const transactionsReq = (data) => {
    return { type: TRANSACTIONS_REQ, payload: data };
};

const transactionsExchangeReq = (data) => {
    return { type: TRANSACTIONS_EXCHANGE_REQ, payload: data };
}

const transactionsSingleReq = (data) => {
    return { type: SINGLE_TRANSACTIONS_REQ, payload: data };
};

const clearErrorReq = () => {
    return { type: CLEAR_ERROR };
};

const transactionsReqFail = (error) => {
    return { type: TRANSACTIONS_REQ_FAIL, payload: error };
};

const transactionsSendReq = (data) => {
    return { type: SEND_TRANSACTIONS_REQ, payload: data };
};
const transactionsSendReqFail = (error) => {
    return { type: SEND_TRANSACTIONS_REQ_FAIL, payload: error };
};

export const transactionsFreeze = (data) => {
    return (dispatch) => {
        dispatch(freezeStart());
        transactionsFreezeApi(data)
            .then((response) => {
                dispatch(freezeSuccess(response.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(transactionsReqFail(error.response.data.message));
            });

    };
};

export const transactionsSingle = (wallet_id) => {
    return (dispatch) => {
        transactionsSingleApi(wallet_id)
            .then((response) => {
                dispatch(transactionsSingleReq(response.data));
            })
            .catch((error) => {
                dispatch(freezeFail(error.response.data.error));
            });
    };
};

export const clearError = () => {
    return (dispatch) => {
        dispatch(clearErrorReq());
    };
}

export const transactions = () => {
    return (dispatch) => {
        transactionsApi()
            .then((response) => {
                dispatch(transactionsReq(response.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(transactionsReqFail(error.response.data.error));
            });

    };
};

export const transactionsExchange = (data) => {
    return (dispatch) => {
        transactionsExchangeApi(data)
            .then((response) => {
                dispatch(transactionsExchangeReq(response.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(transactionsReqFail(error.response.data.message));
            });

    };
};


export const transactionsSend = (data) => {
    return (dispatch) => {
        transactionsSendApi(data)
            .then((response) => {
                dispatch(transactionsSendReq(response.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(transactionsSendReqFail(error.response.data.message));
            });

    };
};




