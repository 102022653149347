import { walletsApi, singlwalletApi, walletsTypesAllApi, openWallet } from '../api/walletsApi';
// import { verifyCustomer } from './customerActions';
// все вызовы переделай на await


// Действия для проверки кода из SMS
export const WALLETS_REQ = 'WALLETS_REQ';
export const WALLETS_REQ_FAIL = 'WALLETS_REQ';
export const SINGL_WALLET_REQ = 'SINGL_WALLET_REQ';
export const SINGL_WALLET_REQ_FAIL = 'SINGL_WALLET_REQ';
export const WALLETS_TYPES_ALL_REQ = 'WALLETS_TYPES_ALL_REQ';
export const WALLETS_TYPES_ALL_REQ_FAIL = 'WALLETS_TYPES_ALL_REQ_FAIL';


const walletsReq = (data) => {
    return { type: WALLETS_REQ, payload: data };
};

const walletsReqFail = (error) => {
    return { type: WALLETS_REQ_FAIL, payload: error };
};

const singlWalletReq = (data) => {
    return { type: SINGL_WALLET_REQ, payload: data };
};

const singlWalletReqFail = (error) => {
    return { type: SINGL_WALLET_REQ_FAIL, payload: error };
};

const walletsTypesAllReq = (data) => {
    return { type: WALLETS_TYPES_ALL_REQ, payload: data };
};

const walletsTypesAllReqFail = (error) => {
    return { type: WALLETS_TYPES_ALL_REQ_FAIL, payload: error };
};






export const wallets = () => {
    return (dispatch) => {
        walletsApi()
            .then((response) => {
                dispatch(walletsReq(response.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(walletsReqFail(error.response.data.error));
            });

    };
};



export const singlWallet = (id) => {
    return (dispatch) => {
        console.log('singlWallet');

        singlwalletApi(id)
            .then((response) => {
                console.log('singlWallet1');

                dispatch(singlWalletReq(response.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(singlWalletReqFail(error.response.data.error));
            });

    };
};

export const walletsTypesAll = () => {
    return (dispatch) => {

        walletsTypesAllApi()
            .then((response) => {
                dispatch(walletsTypesAllReq(response.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(walletsTypesAllReqFail(error.response.data.error));
            });

    };
};


export const openBalance = (id) => {
    return (dispatch) => {
        openWallet(id).then(() => {
            dispatch(wallets());
        })
    }
}



