import { lazy, Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import { wallets } from './actions/wallets'
import { transactions } from './actions/transactions';
import RootRoute from './router'
import { PiTelegramLogoThin } from "react-icons/pi";



function IdurarOs({ customer, wallets, transactions }) {

  useEffect(() => {
    console.log(customer.isAuthenticated);
    if (customer.isAuthenticated) {
      wallets()
      transactions()
    }
  }, [customer.isAuthenticated]);

  return (
    <>
      < RootRoute isAuthenticated={customer?.isAuthenticated} />
{/* 
      <FloatButton.Group
        shape="circle"
        style={{
          insetInlineEnd: 24 + 70,
        }}
      >
        <FloatButton
          href="https://ant.design/index-cn"
          tooltip={<div>Telegram support</div>}
          badge={{
            // count: 5,
            color: 'blue',
          }}
          icon={<PiTelegramLogoThin />}
        />

      </FloatButton.Group> */}
    </>
  )

}

const widthConnect = connect(
  (state) => ({ ...state }),
  (dispatch) => bindActionCreators({ wallets, transactions }, dispatch)
);

export default widthConnect(IdurarOs);