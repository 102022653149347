import { sendRequest } from './request';


export const chartsApi = (coinId) => {
    return sendRequest('/charts/history', 'GET', null, {
        coinId,
    });
};









