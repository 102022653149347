import {
    WALLETS_REQ,
    WALLETS_REQ_FAIL,
    SINGL_WALLET_REQ,
    SINGL_WALLET_REQ_FAIL,
    WALLETS_TYPES_ALL_REQ,
    WALLETS_TYPES_ALL_REQ_FAIL
} from '../actions/wallets';

const initialState = {
    singlWalletData: null,
    walletsTypesAll: null,
    wallets: null,
    loading: false,
    error: null,
};



const wallets = (state = initialState, action) => {
    switch (action.type) {
        case WALLETS_REQ:
            return {
                ...state,
                ...action.payload,
                loading: true
            };
        case WALLETS_REQ_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SINGL_WALLET_REQ:
            return {
                ...state,
                singlWalletData: action.payload
            };
        case SINGL_WALLET_REQ_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case WALLETS_TYPES_ALL_REQ:
            return {
                loading: true,
                ...state,
                walletsTypesAll: action.payload
            };
        case WALLETS_TYPES_ALL_REQ_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};


export default wallets;





