import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { singlWallet, wallets } from '../../actions/wallets';
import { useParams } from 'react-router-dom';
import TopBar from './../../components/topbar';
import { transactionsSend } from './../../actions/transactions'
import { TextField, Button, IconButton, InputAdornment } from '@mui/material';
import { transactions } from '../../actions/transactions';
import { useNavigate } from 'react-router-dom';



const Send = ({ customer, wallets, singlWallet, transactionsSend, transactions, walletsList, transactionsList }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [address, setAddress] = useState('');
    const [amount, setAmountState] = useState('');

    const setAmount = (event) => {
        if (+event.target.value >= 0 && +event.target.value < 99999999999999) {
            setAmountState(+event.target.value);
        }
    };


    const [amountUsdt, setAmountUsdt] = useState(0)
    const [disabledBtn, setDisabledBtn] = useState(true)

    if (transactions?.send) {
        walletsList()
        transactionsList()
        navigate('/home');

    }

    const handleClickMax = () => {
        setAmount(parseFloat((wallets?.singlWalletData?.balance || '0').replaceAll(/,/g, '')))
    };

    const handlePasteFromClipboard = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setAddress(text);
        } catch (err) {
            console.error('Ошибка при чтении из буфера обмена: ', err);
        }
    };

    useEffect(() => {
        singlWallet(id)
    }, [])

    useEffect(() => {
        if (!!address && +amount <= parseFloat((wallets?.singlWalletData?.balance || '0').replaceAll(/,/g, '')) && +amount > 0) {
            setDisabledBtn(false)
        } else {
            setDisabledBtn(true)
        }
    }, [address, amount])

    useEffect(() => {
        if (wallets.singlWalletData) {
            setAmountUsdt(+amount * wallets?.singlWalletData?.price)
        }
    }, [amount]);

    const onSabmit = () => {
        transactionsSend({ wallet_from_id: +wallets?.singlWalletData?.wallet_type.id, wallet_to_hash: address, wallet_from_ammount: +amount })
    }



    if (!wallets.loading) {
        return (
            <div className='loader-block'>
                <div class="w-auto m-y-3 text-xs-center">
                    <div class="d-inline-flex">
                        <div class="tw-loader tw-loader--sm tw-loader--visible" data-testid="loader"></div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <>

                <div id="__next">
                    <div>
                        <div class="np-theme-personal">
                            <div class="np-theme-personal">
                                <div style={{ minHeight: '100vh' }}>
                                    <TopBar size='small' />



                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div class="p-x-2 p-y-5">
                                            <h2 class="text-xs-center m-b-5">

                                                Send {wallets?.singlWalletData?.wallet_type?.code}
                                            </h2>
                                            <div style={{ maxWidth: '400px', margin: 'auto' }}>
                                                <div class="form-group" data-testid="currency-select">
                                                    <div style={{ padding: '10px 10px 10px 10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(245 245 245)', borderRadius: '20px' }}>

                                                        <TextField
                                                            fullWidth
                                                            id="address"
                                                            label="Address or Domain Name"
                                                            type="address"
                                                            autoComplete="address"
                                                            variant="outlined"
                                                            margin="normal"
                                                            value={address}
                                                            onChange={(e) => setAddress(e.target.value)}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <span onClick={handlePasteFromClipboard} style={{ cursor: 'pointer' }}>
                                                                            Paste
                                                                        </span>
                                                                        {/* <IconButton
                                                                  onClick={handleClickPaste}
                                                                >
                                                                    
                                                                </IconButton> */}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            id="amount"
                                                            label={`${wallets?.singlWalletData?.wallet_type?.code} Amount`}
                                                            type="amount"
                                                            autoComplete="amount"
                                                            variant="outlined"
                                                            margin="normal"
                                                            helperText={`= ${amountUsdt.toLocaleString('ru-RU', { minimumFractionDigits: 2 })} $`}
                                                            value={amount}
                                                            onChange={setAmount}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <span onClick={handleClickMax} style={{ cursor: 'pointer' }}>
                                                                            Max
                                                                        </span>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />


                                                    </div>

                                                </div>
                                                <button
                                                    class="btn btn-md np-btn np-btn-md btn-block np-btn-block btn-accent btn-priority-1"
                                                    aria-disabled="false" type="button" aria-live="off"
                                                    aria-busy="false"
                                                    disabled={disabledBtn}
                                                    onClick={() => onSabmit()}
                                                >
                                                    Continue
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};


const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ singlWallet, transactionsSend, walletsList: wallets, transactionsList: transactions }, dispatch)
);

export default widthConnect(Send);