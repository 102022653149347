import { useState, useRef, useEffect } from "react"
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { logoutUser } from './../../actions/customer'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';


const TopBar = ({ customer, logoutUser, toggleClass, size }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleLogout = () => {
        logoutUser();
    };

    const handleGoBack = () => {
        navigate(-1); // Возвращает на предыдущую страницу
    };

    const handleClickOutside = (event) => {

        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {

        document.addEventListener('mousedown', handleClickOutside);

        return () => {

            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    console.log(size);
    if (size == 'small') {
        return (<>
            <div class="np-flow-navigation d-flex align-items-center justify-content-center p-y-3 np-flow-navigation--border-bottom">
                <div class="np-flow-header d-flex flex-wrap align-items-center justify-content-between flex__item--12 np-flow-navigation__content p-x-3 np-flow-navigation--sm">
                    <div class="np-flow-header__left">
                        <a href="/home" class="btn-unstyled" role="link" type="button">
                            <span aria-label="Wise" role="img" class="np-logo">
                                <svg class="np-logo-svg np-logo-svg--size-sm" xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="none">
                                    <path fill="#163300" d="M6.826 7.232 0 15.21h12.188l1.37-3.762H8.335l3.191-3.69.01-.098L9.461 4.09h9.336L11.56 24h4.953L25.25 0H2.676l4.15 7.232Z">
                                    </path>
                                </svg>
                                <svg class="np-logo-svg np-logo-svg--size-md" xmlns="http://www.w3.org/2000/svg" width="106" height="24" fill="none">
                                    <path fill="#163300" d="M58.738.359h6.498l-3.27 23.322h-6.498L58.739.359Zm-8.193 0L46.16 13.794 44.247.359h-4.545L33.96 13.754 33.243.36h-6.299l2.193 23.322h5.223l6.458-14.75 2.272 14.75h5.143L56.725.359h-6.18Zm54.558 13.555H89.674c.08 3.03 1.894 5.023 4.565 5.023 2.014 0 3.608-1.077 4.844-3.13l5.208 2.368C102.501 21.702 98.729 24 94.08 24c-6.339 0-10.545-4.266-10.545-11.123C83.535 5.342 88.478 0 95.455 0c6.14 0 10.007 4.146 10.007 10.605 0 1.076-.12 2.152-.359 3.309Zm-5.78-4.466c0-2.71-1.516-4.425-3.947-4.425-2.512 0-4.585 1.794-5.144 4.425h9.09ZM6.632 7.387 0 15.139h11.844l1.33-3.655H8.1l3.1-3.586.01-.095-2.016-3.471h9.072l-7.032 19.35h4.812L24.538.358H2.6l4.033 7.028Zm69.168-2.364c2.292 0 4.3 1.233 6.055 3.346l.921-6.575C81.143.688 78.93 0 76 0c-5.82 0-9.09 3.409-9.09 7.734 0 3 1.675 4.834 4.426 6.02l1.315.598c2.452 1.047 3.11 1.565 3.11 2.671 0 1.146-1.106 1.874-2.79 1.874-2.782.01-5.034-1.415-6.728-3.847l-.94 6.699C67.234 23.22 69.708 24 72.97 24c5.532 0 8.93-3.19 8.93-7.615 0-3.01-1.335-4.943-4.704-6.458l-1.435-.678c-1.994-.887-2.671-1.375-2.671-2.352 0-1.056.927-1.874 2.71-1.874Z">
                                    </path>
                                </svg>
                            </span>
                        </a>
                    </div>
                    <div class="np-flow-header__right d-flex align-items-center justify-content-end order-2--lg">
                        {/* <div class="tw-avatar tw-avatar--48 tw-avatar--thumbnail">
                            <div class="tw-avatar__content">
                                <img src="https://lh3.googleusercontent.com/a/AATXAJyY19PursRN0nir-WvONJLSbJtExYx6RDJWQUJ3=s96-c" alt="" />
                            </div>
                        </div> */}
                        <div
                            class="tw-avatar tw-avatar--48 tw-avatar--initials np-text-title-body">
                            <div class="tw-avatar__content">
                                {
                                    customer?.data?.last_name[0] + customer?.data?.first_name[0]
                                }
                            </div>
                        </div>
                        <span class="m-x-1">
                        </span>

                        <button onClick={handleGoBack} type="button" class="np-close-button close btn-link text-no-decoration np-close-button--x-large" aria-label="Close">
                            <span class="tw-icon tw-icon-cross " aria-hidden="true" role="none" data-testid="cross-icon">
                                <svg width="24" height="24" fill="currentColor" focusable="false" viewBox="0 0 24 24">
                                    <path d="m19.629 5.915-1.2-1.2-6.257 6.257-6.258-6.257-1.2 1.2 6.258 6.257-6.258 6.257 1.2 1.2 6.258-6.257 6.257 6.257 1.2-1.2-6.258-6.257 6.258-6.257Z">
                                    </path>
                                </svg>
                            </span>
                        </button>

                    </div>
                </div>
            </div>
        </>)
    } else {
        return (<nav class="top-bar navbar navbar-default m-b-0 simple-nav no-keyline">
            <div class="navbar-container d-flex align-items-center">
                <div class="header-container">
                    <button type="button"

                        onClick={toggleClass}
                        class="nui-round-button btn-unstyled navbar-toggle visible-xs-hidden visible-md-block m-r-2"
                        aria-expanded="false" >
                        <div
                            class="tw-avatar nui-icon-avatar clickable tw-avatar--48 tw-avatar--icon">
                            <div class="tw-avatar__content"><span class="tw-icon tw-icon-menu "
                                data-testid="menu-icon"><svg width="24" height="24"
                                    fill="currentColor" focusable="false"
                                    viewBox="0 0 24 24">
                                    <path fill-rule="evenodd"
                                        d="M19.714 6.004H4.286v1.714h15.428V6.004Zm0 5.144H4.286v1.715h15.428v-1.715ZM4.286 16.29h15.428v1.715H4.286v-1.715Z"
                                        clip-rule="evenodd"></path>
                                </svg><span class="sr-only">Panel navigate</span></span>
                            </div>
                        </div>
                    </button>
                </div>
                {/* <button type="button"
        class="np-action-btn np-text-body-default-bold btn-priority-1 invite-pill">Заробити
        50&nbsp;GBP</button> */}

                {/* <ul class="nav navbar-nav notification-center">
                <li><button type="button"
                    class="nui-round-button btn-unstyled notification-center-toggle">
                    <div
                        class="tw-avatar nui-icon-avatar clickable tw-avatar--48 tw-avatar--icon">
                        <div class="tw-avatar__content"><span class="sr-only">Перемикач
                            пошти</span>
                            <div class="notification-center__icon-container"><span
                                class="unread-dot"></span><span
                                    class="sr-only">Unread notifications</span><span
                                        class="tw-icon tw-icon-notification "
                                        aria-hidden="true" role="none"
                                        data-testid="notification-icon"><svg width="24"
                                            height="24" fill="currentColor"
                                            focusable="false" viewBox="0 0 24 24">
                                        <path
                                            d="M20.572 15c-1.543 0-1.715-1.2-1.715-1.715V9c0-3.772-3.085-6.857-6.857-6.857C8.23 2.143 5.143 5.228 5.143 9v4.285C5.143 13.8 4.972 15 3.429 15a.86.86 0 0 0-.857.857v2.571a.86.86 0 0 0 .857.857h6A2.579 2.579 0 0 0 12 21.857a2.579 2.579 0 0 0 2.572-2.572h6a.86.86 0 0 0 .857-.857v-2.571a.86.86 0 0 0-.857-.857ZM12 20.143a.86.86 0 0 1-.857-.858h1.714a.86.86 0 0 1-.857.858Zm7.714-2.572H4.286v-.943c1.757-.343 2.571-1.843 2.571-3.343V9c0-2.829 2.315-5.143 5.143-5.143 2.829 0 5.143 2.314 5.143 5.143v4.285c0 1.5.814 3 2.571 3.343v.943Z">
                                        </path>
                                    </svg></span></div>
                        </div>
                    </div>
                </button></li>
            </ul> */}
                <nav class="profile-menu nav navbar-nav navbar-right">
                    <button
                        id="basic-button"
                        // aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        // aria-expanded={open ? 'true' : undefined}
                        onClick={() => setIsOpen(!isOpen)}

                        type="button"
                        // aria-expanded="false"
                        class="profile-name btn-unstyled"
                    >
                        <span
                            class="sr-only">Open menu</span>
                        <div
                            class="tw-avatar tw-avatar--48 tw-avatar--initials np-text-title-body">
                            <div class="tw-avatar__content">
                                {
                                    customer?.data?.last_name[0] + customer?.data?.first_name[0]
                                }
                            </div>
                        </div>
                        <span class="hidden-xs hidden-sm hidden-md m-l-1 title-5">
                            {
                                customer.data.last_name + ' ' + customer.data.first_name
                            }

                        </span>
                        <span
                            class="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom m-l-1"
                            aria-hidden="true" role="none" data-testid="chevron-up-icon"><svg
                                width="16" height="16" fill="currentColor" focusable="false"
                                viewBox="0 0 24 24">
                                <path
                                    d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z">
                                </path>
                            </svg>
                        </span>
                    </button>

                    {/* <div class=""> */}
                    {isOpen && (
                        <div class='open' ref={dropdownRef}>

                            <ul class="profile-dropdown dropdown-menu dropdown-menu-overlay dropdown-menu-right" role="menu">
                                <li onClick={() => setIsOpen(!isOpen)} class="hidden-sm hidden-md hidden-lg hidden-xl d-flex align-items-center justify-content-between"
                                    role="presentation"><button type="button" role="menuitem" aria-label="Open or close account menu"
                                        class="back-button btn-link text-info text-no-decoration p-a-0 m-l-2">
                                        <div class="tw-avatar nui-icon-avatar clickable tw-avatar--48 tw-avatar--icon">
                                            <div class="tw-avatar__content"><span class="tw-icon tw-icon-arrow-left " aria-hidden="true"
                                                role="none" data-testid="arrow-left-icon"><svg width="24" height="24" fill="currentColor"
                                                    focusable="false" viewBox="0 0 24 24">
                                                    <path
                                                        d="M22.286 11.316H4.629l7.114-7.114-1.2-1.2-8.572 8.571a.829.829 0 0 0 0 1.2l8.572 8.572 1.2-1.2-7.114-7.114h17.657v-1.715Z">
                                                    </path>
                                                </svg></span></div>
                                        </div>
                                    </button></li>
                                <li class="dropdown-header m-b-0" role="presentation">
                                    <div class="m-t-1 m-b-2 d-flex flex-column align-items-center hidden-xl hidden-lg hidden-md hidden-sm"
                                        role="none">
                                        <div class="tw-avatar tw-avatar--48 tw-avatar--thumbnail">
                                            <div class="tw-avatar__content"><img
                                                src="https://lh3.googleusercontent.com/a/AATXAJyY19PursRN0nir-WvONJLSbJtExYx6RDJWQUJ3=s96-c"
                                                alt="" /></div>
                                        </div>
                                    </div>
                                    <div class="dropdown-profile-details d-flex flex-column" role="article">
                                        <h3 class="hidden-xs m-b-0 dropdown-profile-details__name title-4 text-ellipsis text-center">
                                            {
                                                customer.data.last_name + ' ' + customer.data.first_name
                                            }
                                        </h3>
                                        <h3 class="visible-xs-block dropdown-profile-details__name title-2 m-b-0 text-ellipsis text-center">
                                            {
                                                customer.data.last_name + ' ' + customer.data.first_name
                                            }                                        </h3>
                                        <div class="text-secondary font-weight-normal text-center">Membership number  P{customer.data.id}</div><button
                                            type="button"
                                            class="np-action-btn np-text-body-default-bold btn-priority-2 m-t-2 m-l-2 m-r-2 m-b-1"><span
                                                class="tw-icon tw-icon-fast-flag p-r-1" aria-hidden="true" role="none"
                                                data-testid="fast-flag-icon"><svg width="16" height="16" fill="currentColor" focusable="false"
                                                    viewBox="0 0 24 24">
                                                    <path
                                                        d="m1.875 14.588 5.475-6.44h-.002L4.02 2.308h18.105l-7.008 19.376h-3.97L16.95 5.609H9.463l1.665 2.883-.008.08-2.56 2.979h4.188l-1.098 3.037H1.875Z">
                                                    </path>
                                                </svg></span>Get your Wisetag</button>
                                    </div>
                                </li>
                                {/*  <li class="profile-dropdown__primary-item" role="presentation"><a role="menuitem" class="generic-menu-item"
                            href="/profile/view">
                            <div class="icon-container" role="none"><span class="tw-icon tw-icon-profile " aria-hidden="true"
                                role="none" data-testid="profile-icon"><svg width="24" height="24" fill="currentColor"
                                    focusable="false" viewBox="0 0 24 24">
                                    <path
                                        d="M15.257 14.014A5.922 5.922 0 0 0 18 9c0-3.3-2.7-6-6-6S6 5.7 6 9c0 2.1 1.071 3.943 2.743 5.014-2.614 1.243-4.457 3.9-4.457 6.986H6c0-3.3 2.7-6 6-6s6 2.7 6 6h1.714c0-3.086-1.842-5.743-4.457-6.986ZM7.714 9A4.298 4.298 0 0 1 12 4.714 4.298 4.298 0 0 1 16.286 9 4.298 4.298 0 0 1 12 13.286 4.298 4.298 0 0 1 7.714 9Z">
                                    </path>
                                </svg></span></div><span>Personal details</span>
                        </a></li>
                        <li class="profile-dropdown__primary-item" role="presentation"><a role="menuitem" class="generic-menu-item"
                            href="/balances/statements">
                            <div class="icon-container" role="none"><span class="tw-icon tw-icon-documents " aria-hidden="true"
                                role="none" data-testid="documents-icon"><svg width="24" height="24" fill="currentColor"
                                    focusable="false" viewBox="0 0 24 24">
                                    <path fill-rule="evenodd"
                                        d="m15.6 1.97 5.143 5.143a.846.846 0 0 1 .257.6v10.286a.86.86 0 0 1-.857.857H7.286a.86.86 0 0 1-.857-.857V2.57a.86.86 0 0 1 .857-.857H15c.214 0 .429.086.6.257ZM15 7.713h3.943L15 3.77v3.943ZM8.143 3.427v13.715h11.143V9.427h-5.143a.86.86 0 0 1-.857-.857V3.427H8.143ZM3 5.141h1.714v15.428h11.143v1.715h-12A.86.86 0 0 1 3 21.425V5.141Z"
                                        clip-rule="evenodd"></path>
                                </svg></span></div><span>Statements and reports</span>
                        </a></li>
                        <li class="profile-dropdown__primary-item" role="presentation"><a role="menuitem" class="generic-menu-item"
                            href="/settings">
                            <div class="icon-container" role="none"><span class="tw-icon tw-icon-settings " aria-hidden="true"
                                role="none" data-testid="settings-icon"><svg width="24" height="24" fill="currentColor"
                                    focusable="false" viewBox="0 0 24 24">
                                    <path fill-rule="evenodd"
                                        d="m20.34 13.456 1.371.814c.215.129.343.3.343.6.043.214.043.429-.085.643l-1.972 3.386c-.128.171-.3.342-.514.385-.215.043-.429.043-.643-.085l-1.372-.815c-.728.643-1.585 1.115-2.528 1.457v1.586a.86.86 0 0 1-.857.857H10.14a.86.86 0 0 1-.857-.857v-1.671a8.816 8.816 0 0 1-2.529-1.457l-1.371.814c-.172.128-.429.128-.643.086-.214-.043-.386-.172-.514-.386l-1.972-3.386c-.086-.171-.128-.428-.086-.643.043-.214.172-.385.386-.514l1.372-.814a8.369 8.369 0 0 1-.129-1.457c0-.472.043-.943.129-1.458l-1.372-.814c-.171-.128-.343-.3-.386-.514-.042-.214-.042-.429.086-.643l2.057-3.471c.129-.172.3-.343.515-.386.214-.043.428-.043.642.086l1.372.814c.728-.643 1.586-1.114 2.528-1.457V2.57a.86.86 0 0 1 .858-.857h3.942a.86.86 0 0 1 .858.857v1.629a8.82 8.82 0 0 1 2.528 1.457l1.372-.815c.171-.128.428-.128.643-.085.214.043.385.171.514.385L22.01 8.57c.129.171.129.429.086.643-.043.214-.171.386-.386.514l-1.371.814c.086.472.128.986.128 1.458 0 .471-.042.942-.128 1.457Zm-1.586-1.457c0-.558-.085-1.115-.214-1.672-.086-.386.043-.771.471-.986l1.2-.685-1.114-1.929-1.2.686a.838.838 0 0 1-1.028-.129c-.815-.771-1.8-1.371-2.915-1.671a.826.826 0 0 1-.643-.814V3.427h-2.228v1.414a.826.826 0 0 1-.643.815 6.43 6.43 0 0 0-2.914 1.671.838.838 0 0 1-1.029.129l-1.2-.686-1.114 1.929 1.2.685c.3.172.471.557.385.943A7.446 7.446 0 0 0 5.554 12c0 .557.086 1.114.214 1.671.129.343-.042.729-.385.943l-1.2.686 1.114 1.928 1.114-.643a.838.838 0 0 1 1.029.129c.814.771 1.8 1.371 2.914 1.671a.826.826 0 0 1 .643.815v1.371h2.229v-1.414c0-.386.257-.729.642-.814a6.43 6.43 0 0 0 2.915-1.672.838.838 0 0 1 1.028-.129l1.2.686 1.115-1.928-1.2-.686c-.3-.171-.472-.557-.386-.943a7.444 7.444 0 0 0 .214-1.671Zm-10.886 0a4.298 4.298 0 0 1 4.286-4.286 4.298 4.298 0 0 1 4.286 4.286 4.298 4.298 0 0 1-4.286 4.285A4.298 4.298 0 0 1 7.868 12Zm1.715 0a2.579 2.579 0 0 0 2.571 2.571 2.579 2.579 0 0 0 2.572-2.571 2.579 2.579 0 0 0-2.572-2.572A2.579 2.579 0 0 0 9.583 12Z"
                                        clip-rule="evenodd"></path>
                                </svg></span></div><span>Settings</span>
                        </a></li>
                       
                        <li class="profile-dropdown__primary-item" role="presentation"><a role="menuitem" class="generic-menu-item"
                            href="/terms-and-conditions">
                            <div class="icon-container" role="none"><span class="tw-icon tw-icon-document " aria-hidden="true"
                                role="none" data-testid="document-icon"><svg width="24" height="24" fill="currentColor"
                                    focusable="false" viewBox="0 0 24 24">
                                    <path
                                        d="m19.457 7.115-5.143-5.143a.846.846 0 0 0-.6-.257H5.143a.86.86 0 0 0-.857.857v18.857a.86.86 0 0 0 .857.857h13.714a.86.86 0 0 0 .857-.857V7.715a.846.846 0 0 0-.257-.6Zm-1.8.6h-3.943V3.772l3.943 3.943ZM6 20.572V3.429h6v5.143a.86.86 0 0 0 .857.857H18v11.143H6Z">
                                    </path>
                                </svg></span></div><span>Our agreements</span>
                        </a></li> */}
                                <li class="profile-dropdown__primary-item" role="presentation"><a role="menuitem" class="generic-menu-item"
                                    href="https://t.me/wisebit_support">
                                    <div class="icon-container" role="none"><span class="tw-icon tw-icon-help-circle " aria-hidden="true"
                                        role="none" data-testid="help-circle-icon"><svg width="24" height="24" fill="currentColor"
                                            focusable="false" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd"
                                                d="M12 1.717c-5.657 0-10.286 4.628-10.286 10.285 0 5.658 4.63 10.286 10.286 10.286 5.657 0 10.286-4.628 10.286-10.286 0-5.657-4.629-10.285-10.286-10.285Zm0 18.857c-4.714 0-8.571-3.857-8.571-8.572 0-4.714 3.857-8.57 8.571-8.57 4.715 0 8.572 3.856 8.572 8.57 0 4.715-3.857 8.572-8.572 8.572Zm0-2.569a1.286 1.286 0 1 0 0-2.571 1.286 1.286 0 0 0 0 2.571ZM8.7 10.29c0-1.972 1.372-3.429 3.3-3.429 1.929 0 3.3 1.372 3.3 3.3 0 1.072-.771 1.886-1.457 2.572-.471.471-.986.986-.986 1.371h-1.714c0-1.071.772-1.885 1.457-2.571.472-.472.986-.986.986-1.372 0-.985-.6-1.585-1.586-1.585-.943 0-1.585.685-1.585 1.714H8.7Z"
                                                clip-rule="evenodd"></path>
                                        </svg></span></div><span>Help</span>
                                </a></li>
                                <li class="profile-dropdown__primary-item" role="presentation">
                                    <label role="menuitem" class="generic-menu-item" onClick={handleLogout}>
                                        <div class="icon-container" role="none">
                                            <span class="tw-icon tw-icon-log-out " aria-hidden="true" role="none" data-testid="log-out-icon">
                                                <svg width="24" height="24" fill="currentColor" focusable="false" viewBox="0 0 24 24">
                                                    <path fill-rule="evenodd"
                                                        d="M17.143 3.431H3.429v17.143h13.714v1.714H2.571a.86.86 0 0 1-.857-.857V2.574a.86.86 0 0 1 .857-.857h14.572V3.43Zm-1.157 1.885 6.043 6.086c.343.343.343.857-.043 1.2l-6.043 6.043-1.2-1.2 4.629-4.586H6v-1.714h13.372l-4.586-4.629 1.2-1.2Z"
                                                        clip-rule="evenodd">
                                                    </path>
                                                </svg>
                                            </span>
                                        </div>
                                        <span>Log out</span>
                                    </label>
                                </li>
                                {/* <li class="divider m-t-4 m-b-3 m-x-2" role="separator"></li>
                        <li role="presentation"><a href="/business-onboarding" role="menuitem"
                            class="np-option upsell-menu-item decision np-option__sm-media">
                            <div class="media">
                                <div class="media-left">
                                    <div class="np-option__no-media-circle">
                                        <div class="tw-avatar nui-icon-avatar tw-avatar--48 tw-avatar--icon">
                                            <div class="tw-avatar__content"><span class="tw-icon tw-icon-plus " aria-hidden="true"
                                                role="none" data-testid="plus-icon"><svg width="24" height="24"
                                                    fill="currentColor" focusable="false" viewBox="0 0 24 24">
                                                    <path
                                                        d="M22.286 11.143h-9.429V1.715h-1.714v9.428H1.714v1.715h9.429v9.428h1.714v-9.428h9.429v-1.715Z">
                                                    </path>
                                                </svg></span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="media-body">
                                    <h4 class="np-text-body-large-bold text-primary np-option__title">Open a business account</h4>
                                </div>
                                <div class="media-right"></div>
                            </div>
                        </a></li> */}
                            </ul>
                        </div>
                    )}
                </nav>
            </div>
        </nav>)
    }
}



const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ logoutUser }, dispatch)
);

export default widthConnect(TopBar);