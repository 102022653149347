import { sendRequest } from './request';



// Функция для проверки кода из SMS и получения JWT токена
export const walletsApi = () => {
    return sendRequest('/wallets', 'GET');
};

export const singlwalletApi = (id) => {
    return sendRequest(`/wallets/${id}`, 'GET');
};

export const walletsTypesAllApi = (id) => {
    return sendRequest(`/wallets/types/all`, 'GET');
};

export const openWallet = (id) => {
    return sendRequest(`/wallets/`, 'POST', {wallet_id: +id});
};


