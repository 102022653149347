import { chartsApi } from '../api/chartsApi';


export const CHARTS_REQ = 'CHARTS_REQ';
export const CHARTS_REQ_FAIL = 'CHARTS_REQ_FAIL';


const chartsReq = (data) => {
    return { type: CHARTS_REQ, payload: data };
};

const chartsReqFail = (error) => {
    return { type: CHARTS_REQ_FAIL, payload: error };
};

export const chartsList = (coinId) => {
    return (dispatch) => {
        chartsApi(coinId)
            .then((response) => {
                dispatch(chartsReq(response.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(chartsReqFail(error.response.data.error));
            });

    };
};
