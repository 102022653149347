import { auth } from '../api/authApi';
import { transactions } from './transactions';
import { wallets } from './wallets';
import { regRequest } from '../api/registration';

// import { verifyCustomer } from './customerActions';
// все вызовы переделай на await


// Действия для проверки кода из SMS
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const REG_START = 'REG_START';
export const REG_SUCCESS = 'REG_SUCCESS';
export const REG_FAIL = 'REG_FAIL';
export const DELETE_MNEMONIC = 'DELETE_MNEMONIC';



const loginStart = () => {
    return { type: LOGIN_START };
};

export const logout = () => {
    return { type: LOGOUT };
};

const loginSuccess = (data) => {
    return { type: LOGIN_SUCCESS, payload: data };
};

const loginFail = (error) => {
    return { type: LOGIN_FAIL, payload: error };
};



const regStart = () => {
    return { type: REG_START };
};

const regSuccess = (data) => {
    return { type: REG_SUCCESS, payload: data };
};

const regFail = (error) => {
    return { type: REG_FAIL, payload: error };
};

const delete_mnemonic = () => {
    console.log('type', DELETE_MNEMONIC);
    return { type: DELETE_MNEMONIC };
};


// Асинхронная функция для проверки кода из SMS
export const login = (mail, pass) => {
    return (dispatch) => {
        // const {
        // dispatch(verifyCustomer());
        dispatch(loginStart());

        auth(mail, pass)
            .then((response) => {
                dispatch(loginSuccess(response.data));
                wallets()
                transactions()
            })
            .catch((error) => {
                console.log(error);
                dispatch(loginFail(error.response.data.error));
            });

    };
};

export const registration = (email, pass, navigate) => {
    return (dispatch) => {

        dispatch(regStart());

        regRequest(email, pass)
            .then((response) => {
                dispatch(regSuccess(response.data));
                if(navigate) navigate('/mnemonic')
            })
            .catch((error) => {
                console.log(error);
                dispatch(regFail(error.response.data.error));
            });

    };
};

export const logoutUser = () => {
    return (dispatch) => {
        localStorage.clear();
        dispatch(logout());
    };
};

export const deletemnemonic = () => {
    return (dispatch) => {
        dispatch(delete_mnemonic());
    };
};






