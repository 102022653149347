import { useEffect, useState } from 'react';
import { TextField, Button, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { registration } from '../actions/customer'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';

const RegisterPage = ({ registration }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const [passwordError, setPasswordError] = useState(null);
  const [errorPassword, setErrorPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowPasswordRepeat = () => setShowPasswordRepeat(!showPasswordRepeat);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPasswordRepeat = (event) => {
    event.preventDefault();
  };

  const handleClickSabmit = () => {
    let EmailError = null;
    let PasswordError = null;


    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setEmailError(true);
      EmailError = true;
      setErrorMessage('Please enter a valid email address');
    } else {
      EmailError = false
      setEmailError(false);
      setErrorMessage('');
      if (password.length < 7) {
        setPasswordError(true);
        PasswordError = true;
        setErrorPassword('Password must be more than 8 characters');
      } else {
        if (password !== passwordRepeat) {
          setPasswordError(true);
          PasswordError = true;
          setErrorPassword('Passwords dont match');
        } else {
          PasswordError = false;
          setPasswordError(false);
          setErrorPassword('');
        }
      }
    }


    if (password.length < 7) {
      setPasswordError(true);
      PasswordError = true;
      setErrorPassword('Password must be more than 8 characters');
    } else {
      if (password !== passwordRepeat) {
        setPasswordError(true);
        PasswordError = true;
        setErrorPassword('Passwords dont match');
      } else {
        PasswordError = false;
        setPasswordError(false);
        setErrorPassword('');
      }
    }

    console.log(PasswordError, EmailError);
    if (emailError === false && passwordError === false) {
      registration(email, password)
      navigate('/home')
      // setTimeout(() => { navigate('/mnemonic') }, 500)
    } else if (PasswordError === false && EmailError === false) {
      registration(email, password)
      navigate('/home')
      // setTimeout(() => { navigate('/mnemonic') }, 500)
    }




  }

  return (
    <>
      <div id="__next">
        <div class="np-theme-personal">
          <div class="Signup_signup-container__hqvkk Signup_signup-container--hidden-nav__Z_n_m">
            <div class="np-theme-personal">
              <div class="FlowWrapper_logged-out-flow__4t8Vg"
                style={{ transition: 'background-color 100ms ease-in', minHeight: '100vh' }}
              >
                <div class="tw-flow">

                  <div class="np-overlay-header d-flex justify-content-center">
                    <div
                      class="np-flow-header d-flex flex-wrap align-items-center justify-content-between flex__item--12 np-overlay-header__content p-a-3">
                      <a href="/" title="Go to homepage"><span aria-label="Wise" role="img" class="np-logo"><svg
                        class="np-logo-svg np-logo-svg--size-sm" xmlns="http://www.w3.org/2000/svg"
                        width="26" height="24" fill="none">
                        <path fill="#163300"
                          d="M6.826 7.232 0 15.21h12.188l1.37-3.762H8.335l3.191-3.69.01-.098L9.461 4.09h9.336L11.56 24h4.953L25.25 0H2.676l4.15 7.232Z">
                        </path>
                      </svg><svg class="np-logo-svg np-logo-svg--size-md"
                        xmlns="http://www.w3.org/2000/svg" width="106" height="24" fill="none">
                          <path fill="#163300"
                            d="M58.738.359h6.498l-3.27 23.322h-6.498L58.739.359Zm-8.193 0L46.16 13.794 44.247.359h-4.545L33.96 13.754 33.243.36h-6.299l2.193 23.322h5.223l6.458-14.75 2.272 14.75h5.143L56.725.359h-6.18Zm54.558 13.555H89.674c.08 3.03 1.894 5.023 4.565 5.023 2.014 0 3.608-1.077 4.844-3.13l5.208 2.368C102.501 21.702 98.729 24 94.08 24c-6.339 0-10.545-4.266-10.545-11.123C83.535 5.342 88.478 0 95.455 0c6.14 0 10.007 4.146 10.007 10.605 0 1.076-.12 2.152-.359 3.309Zm-5.78-4.466c0-2.71-1.516-4.425-3.947-4.425-2.512 0-4.585 1.794-5.144 4.425h9.09ZM6.632 7.387 0 15.139h11.844l1.33-3.655H8.1l3.1-3.586.01-.095-2.016-3.471h9.072l-7.032 19.35h4.812L24.538.358H2.6l4.033 7.028Zm69.168-2.364c2.292 0 4.3 1.233 6.055 3.346l.921-6.575C81.143.688 78.93 0 76 0c-5.82 0-9.09 3.409-9.09 7.734 0 3 1.675 4.834 4.426 6.02l1.315.598c2.452 1.047 3.11 1.565 3.11 2.671 0 1.146-1.106 1.874-2.79 1.874-2.782.01-5.034-1.415-6.728-3.847l-.94 6.699C67.234 23.22 69.708 24 72.97 24c5.532 0 8.93-3.19 8.93-7.615 0-3.01-1.335-4.943-4.704-6.458l-1.435-.678c-1.994-.887-2.671-1.375-2.671-2.352 0-1.056.927-1.874 2.71-1.874Z">
                          </path>
                        </svg> <span class="crypto-logo">bit</span></span></a>
                      <div class="d-flex align-items-center order-2"><button type="button"
                        class="np-close-button close btn-link text-no-decoration np-close-button--x-large"
                        aria-label="Close"><span class="tw-icon tw-icon-cross " aria-hidden="true"
                          role="none" data-testid="cross-icon"><svg width="24" height="24"
                            fill="currentColor" focusable="false" viewBox="0 0 24 24">
                            <path
                              d="m19.629 5.915-1.2-1.2-6.257 6.257-6.258-6.257-1.2 1.2 6.258 6.257-6.258 6.257 1.2 1.2 6.258-6.257 6.257 6.257 1.2-1.2-6.258-6.257 6.258-6.257Z">
                            </path>
                          </svg></span></button></div>
                      <div class="align-items-center d-flex justify-content-center order-1 flex-grow-1"></div>
                    </div>
                  </div>
                  <div>
                    <section class="tw-flow__main"
                    // style="position: relative;"
                    >
                      <div class="tw-flow__steps"
                      // style=""
                      >
                        <div class="tw-flow__step container">
                          <div class="m-b-3 m-t-5">
                            <div class="Container_flow-step__container__2f3en">
                              <div class="col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6">
                                <div class="text-xs-center">
                                  <h1 class="h2 m-t-2 StepLayout_step-title--subtitle__ZzRVv">Create your Wise account</h1>
                                  <p class="m-b-5">Already have an account? <a href="/login">Log in</a></p>
                                  <div action="#" method="post">
                                    <div class="cf-turnstile" data-sitekey="0x4AAAAAAAhQSdxkv9DCH7jF" data-callback="turnstileCallback" data-error-callback="turnstileErrorCallback">
                                      <div>
                                        <input type="hidden" name="cf-turnstile-response" id="cf-chl-widget-8znhr_response" value="0.HYnCdUmsotWzKT9cLNWUnUVldyAqgYx4H7Ba3ZeSQSjiote-MgR6RrWVZETGb9m-fp5CbUimZEry3Ua8vYbCZJZvllyce2Ry7ZJUtiFJ1kmFOOLI1hZpud05zLepcLH-IziSr1l35yVAFqwN4juizK2miRDuML2E_poQx7GgaeH462wGg_qouM8eIWmxMQRgu2myWyqluIHq3Q02lBN0Nnz6bYvujzsLCaYLEgJYHI2l2lWBsI4gRCvR0nSv8C1-icQMxsueI-VFkb0LOPoLySh3eI9PLZ6-VMot2w0N6cy079bSjYbwGW-6SbVWTcvXEkOI5t7tVhZKpjq60pPr3Hm57WMlxdHQmiIVCaRWxyfsoitXkou5C5DEIWP35SbxIS28CgkT79qFYmFl7dV_RyahuKNPMmsH6wQmak4xtnY.YoH_6yy5diHgWG1qzMP9bg.c2247fcf3b3423c85f99f8c387666a3b25691601544035db6a81d8b9168cb778" />
                                      </div>
                                    </div>
                                    <TextField
                                      fullWidth
                                      id="email"
                                      label="Your email address"
                                      type="email"
                                      autoComplete="email"
                                      variant="outlined"
                                      margin="normal"
                                      helperText={errorMessage}
                                      error={emailError}
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)} // Update email state
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          borderRadius: '12px' // задаём нужное значение borderRadius
                                        }
                                      }}
                                    />


                                    <TextField
                                      fullWidth
                                      id="password"
                                      label="Your password"
                                      type={showPassword ? 'text' : 'password'}
                                      autoComplete="current-password"
                                      variant="outlined"
                                      margin="normal"
                                      error={passwordError}
                                      value={password}
                                      helperText={errorPassword}
                                      onChange={(e) => setPassword(e.target.value)} // Update password state
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword}
                                              onMouseDown={handleMouseDownPassword}
                                            >
                                              {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />

                                    <TextField
                                      fullWidth
                                      id="password"
                                      label="Your password"
                                      type={showPasswordRepeat ? 'text' : 'password'}
                                      autoComplete="current-password"
                                      variant="outlined"
                                      margin="normal"
                                      error={passwordError}
                                      helperText={errorPassword}
                                      value={passwordRepeat}
                                      onChange={(e) => setPasswordRepeat(e.target.value)} // Update password state
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPasswordRepeat}
                                              onMouseDown={handleMouseDownPasswordRepeat}
                                            >
                                              {showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />



                                    <button onClick={() => handleClickSabmit()} class="btn btn-md np-btn np-btn-md btn-block np-btn-block btn-accent btn-priority-1 m-t-2" title="Next" aria-label="Next" type="submit" aria-live="off">Next</button>
                                  </div>

                                  {/* <p class="text-xs-center m-t-4">By registering, you accept our <a target="_blank" href="/terms-and-conditions">Terms of use</a> and <a target="_blank" href="/privacy-policy">Privacy Policy</a></p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const widthConnect = connect(
  (state) => ({ ...state }),
  (dispatch) => bindActionCreators({ registration }, dispatch)
);

export default widthConnect(RegisterPage);