import {
   CHARTS_REQ,
   CHARTS_REQ_FAIL
} from '../actions/charts';

const initialState = {
    list: null,
    loading: false,
    error: null,
};

const charts = (state = initialState, action) => {
    switch (action.type) {
        case CHARTS_REQ:
            return {
                ...state,
                list: action.payload
            };
        case CHARTS_REQ_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default charts;





