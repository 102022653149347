import { launchpoolsApi } from '../api/launchpools';


export const LAUNCHPOOLS_REQ_START = 'LAUNCHPOOLS_REQ_START';
export const LAUNCHPOOLS_REQ_SUCCESS = 'LAUNCHPOOLS_REQ_SUCCESS';
export const LAUNCHPOOLS_REQ_FAIL = 'LAUNCHPOOLS_REQ_FAIL';


const launchpoolsStart = () => {
    return { type: LAUNCHPOOLS_REQ_START };
};

const launchpoolsSuccess = (data) => {
    return { type: LAUNCHPOOLS_REQ_SUCCESS, payload: data };
};

const launchpoolsFail = (error) => {
    return { type: LAUNCHPOOLS_REQ_FAIL, payload: error };
};




export const launchpoolsList = () => {
    return (dispatch) => {
        dispatch(launchpoolsStart());
        launchpoolsApi()
            .then((response) => {
                dispatch(launchpoolsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(launchpoolsFail(error.response.data.error));
            });
    };
};

