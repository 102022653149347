import {

    LOGIN_START,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    REG_START,
    REG_SUCCESS,
    REG_FAIL,
    DELETE_MNEMONIC
} from '../actions/customer';

const initialState = {
    token: null,
    isAuthenticated: false,
    data: null,
    mnemonic: null,
    loading: false,
    error: null,
};

const customer = (state = initialState, action) => {
    let globalStore = null
    console.log(action.type);
    switch (action.type) {
        case LOGIN_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case DELETE_MNEMONIC:
            return {
                ...state,
                loading: true,
                error: null,
                mnemonic: null,
                data: {
                    ...state.data, 
                    mnemonic: null, 
                },
            };
        case LOGOUT:
            return {
                ...initialState,
            };
        case REG_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case REG_SUCCESS:
            globalStore =
                JSON.parse(localStorage.getItem('globalStore')) || {};
            localStorage.setItem(
                'globalStore',
                JSON.stringify({
                    ...globalStore,
                    customer: {
                        ...globalStore?.customer,
                        token: action.payload.token,
                        data: action.payload.customer,
                        isAuthenticated: true,
                    },
                }),
            );

            return {
                ...state,
                mnemonic: action.payload.mnemonic,
                token: action.payload.token,
                data: action.payload.customer,
                isAuthenticated: true,
                loading: false,
            };
        case LOGIN_SUCCESS:
            globalStore =
                JSON.parse(localStorage.getItem('globalStore')) || {};
            localStorage.setItem(
                'globalStore',
                JSON.stringify({
                    ...globalStore,
                    customer: {
                        ...globalStore?.customer,
                        token: action.payload.token,
                        data: action.payload.customer,


                        isAuthenticated: true,
                    },
                }),
            );
            console.log(action.payload);

            return {
                ...state,
                token: action.payload.token,
                data: action.payload.customer,
                isAuthenticated: true,
                loading: false,
            };
        case LOGIN_FAIL:
        case REG_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default customer;





