import customerReducer from './customer'
import wallets from './wallets'
import transactions from './transactions'
import charts from './charts'
import launchpools from './launchpools'

export default {
    customer: customerReducer,
    wallets: wallets,
    transactions,
    charts,
    launchpools
};
