import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MainSlider from '../../components/mainslider'
import { Transaction } from '../../components/transactions';
import { CryptoChart } from '../../components/chart'
import { chartsList } from './../../actions/charts'
import { walletsTypesAll } from './../../actions/wallets'
import TopBar from '../../components/topbar'
import { SideBar } from '../../components/sidebar'

const Empty = ({ }) => {
  return (
    <div class="css-26y7iw">
      <div class="p-x-2 p-b-5">
        <div style={{ maxWidth: '520px', margin: 'auto' }}>
          <div class="text-xs-center">
            <picture>
              <img alt="" data-testid="wds-wallet-illustration" class="wds-illustration wds-illustration-wallet wds-illustration-padding" loading="eager" src="https://wise.com/web-art/assets/illustrations/wallet-small@1x.webp" srcset="https://wise.com/web-art/assets/illustrations/wallet-small@2x.webp 2x" width="200" height="200" />
            </picture>
            <h1 class="np-text-display-small text-primary m-y-3">
              The safe home for your money
            </h1>
          </div>
          <div class="p-x-2">
            <div class="np-summary d-flex align-items-start">
              <div class="np-summary__icon">
                <span class="tw-icon tw-icon-payments " aria-hidden="true" role="none" data-testid="payments-icon">
                  <svg width="24" height="24" fill="currentColor" focusable="false" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M6.857 3.43h4.359l-9.465 9.464 1.212 1.212 9.465-9.465v4.36h1.715V2.576a.861.861 0 0 0-.862-.861H6.857v1.714Zm10.286 17.142h-4.36l9.466-9.466-1.212-1.212-9.466 9.465V15H9.857v6.424c0 .476.386.862.861.862h6.425v-1.714Z" clip-rule="evenodd">
                    </path>
                  </svg>
                </span>
              </div>
              <div class="np-summary__body m-l-2">
                <div class="np-summary__title d-flex">
                  <span role="heading" aria-level="6" class="np-text-body-large-bold text-primary m-b-1">
                    Add, convert, send, and receive money securely
                  </span>
                </div>
              </div>
            </div>
            <div class="np-summary d-flex align-items-start">
              <div class="np-summary__icon">
                <span class="tw-icon tw-icon-shield " aria-hidden="true" role="none" data-testid="shield-icon">
                  <svg width="24" height="24" fill="currentColor" focusable="false" viewBox="0 0 24 24">
                    <path d="M21.521 5.015c-.042-.429-.428-.729-.857-.729-5.443 0-7.971-2.314-7.971-2.314a.829.829 0 0 0-1.2 0S9.05 4.286 3.52 4.286c-.428 0-.814.3-.857.729 0 .128-1.671 13.586 9.172 17.228.085.043.171.043.257.043s.171 0 .257-.043c10.843-3.6 9.171-17.057 9.171-17.228Zm-2.142 6.857c-1.029 4.414-3.515 7.329-7.286 8.657-3.814-1.371-6.257-4.286-7.286-8.657-.6-2.443-.557-4.757-.471-5.871 4.2-.172 6.728-1.543 7.8-2.315 1.071.772 3.6 2.143 7.8 2.315 0 1.114.043 3.385-.557 5.871Z">
                    </path>
                  </svg>
                </span>
              </div>
              <div class="np-summary__body m-l-2">
                <div class="np-summary__title d-flex">
                  <span role="heading" aria-level="6" class="np-text-body-large-bold text-primary m-b-1">
                    We safeguard 100% of your cash
                  </span>
                </div>
              </div>
            </div>
            <div class="np-summary d-flex align-items-start">
              <div class="np-summary__icon">
                <span class="tw-icon tw-icon-id " aria-hidden="true" role="none" data-testid="id-icon">
                  <svg width="24" height="24" fill="currentColor" focusable="false" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M2.576 4.713a.861.861 0 0 0-.862.861v12.849c0 .475.386.861.862.861h18.848a.861.861 0 0 0 .862-.861V5.574a.861.861 0 0 0-.862-.861H2.576Zm.853 12.857V6.427H20.57V17.57H3.43Zm15.428-4.714h-5.571v1.714h5.571v-1.714Zm-5.571-3.429h3.428v1.715h-3.428V9.427Zm-6.429 4.286v-3.429h3v3.429h-3ZM5.143 8.999c0-.237.192-.429.428-.429h5.572c.236 0 .428.192.428.429v6a.429.429 0 0 1-.428.428H5.57A.429.429 0 0 1 5.143 15v-6Z" clip-rule="evenodd">
                    </path>
                  </svg>
                </span>
              </div>
              <div class="np-summary__body m-l-2">
                <div class="np-summary__title d-flex">
                  <span role="heading" aria-level="6" class="np-text-body-large-bold text-primary m-b-1">
                    We follow strict regulations, everywhere we work
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const HomePage = ({ customer, wallets, chartsList, charts, transactions, walletsTypesAll }) => {
  const [isOpenBurger, setIsOpen] = useState(false);
  const toggleClass = () => {
    setIsOpen(!isOpenBurger);
  };

  useEffect(() => {
    if (customer.isAuthenticated) {
      walletsTypesAll()
    }
  }, [customer.isAuthenticated]);

  // console.log(customer.data.last_name, customer.data.first_name);
  if (!wallets.loading) {
    return (
      <div className='loader-block'>
        <div class="w-auto m-y-3 text-xs-center">
          <div class="d-inline-flex">
            <div class="tw-loader tw-loader--sm tw-loader--visible" data-testid="loader"></div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <>

        <div id="__next">
          <div class="np-theme-personal">
            <div class="activity-show-sensitive">
              <div class="np-theme-personal">
                <div class="page-layout">
                  <div class="column-layout">
                    <SideBar toggleClass={toggleClass} isOpenBurger={isOpenBurger} />
                    <div class="column-layout-main">
                      <TopBar customer={customer} toggleClass={toggleClass} />
                      <div id="main" class="container-content">
                        <div
                        // style="opacity:1;pointer-events:all"
                        >
                          <div class="Launchpad_launchpadComponentsContainer__jRN7N">
                            <div
                            // style="height:auto"
                            >
                              <div class="Section_section__stusW">
                                <div class=""
                                // style="transform:none"
                                >
                                  <div>
                                    <div class="np-text-body-large">Total balance</div>
                                    <div class="Title_textAndIconContainer__zkn9Y">
                                      <h1 class="np-text-title-screen"><span
                                        class="parseMarkupTags_sensitive">{wallets?.balance_total} </span>
                                        USD</h1>
                                      {/* <button
                                        class="TitleActionButton_circularButton__zd4E4"
                                        type="button" aria-label="Account summary">
                                         <div
                                          class="tw-avatar TitleActionButton_avatar__gpCjS tw-avatar--24 tw-avatar--icon">
                                          <div class="tw-avatar__content">
                                            <span><span
                                              class="tw-icon tw-icon-bar-chart "
                                              aria-hidden="true" role="none"
                                              data-testid="bar-chart-icon"><svg
                                                width="16" height="16"
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24">
                                                <path
                                                  d="M18 18.856V3.428h-1.714v15.428h-3.429V7.713h-1.714v11.143H7.714v-6H6v6H4.286v1.715h15.428v-1.715H18Z">
                                                </path>
                                              </svg></span></span>
                                          </div>
                                        </div>
                                      </button> */}
                                    </div>
                                  </div>
                                </div>
                                {/* <div
                                  class="Section_section__stusW Section_sectionLowerLayer__fiiyt">
                                  <div class="Section_horizontalScrollSection__5Iu1T Section_buttons__PskJ_"
                                  // style="transform:none"
                                  >
                                    <div class="Button_container__9TnuA"><a
                                      href="/link?urn=urn%3Awise%3Atransfers%3F%3Daction%3Dcreate"
                                      class="np-action-btn np-text-body-default-bold Button_button__exqP_ btn-priority-1 Button_primary__DEC_1">Send</a>
                                    </div>
                                    <div class="Button_container__9TnuA"><a
                                      href="/link?urn=urn%3Awise%3Abalances%3F%3Daction%3DtopUp"
                                      class="np-action-btn np-text-body-default-bold Button_button__exqP_ btn-priority-2 Button_secondary__9ex2D">Income</a></div>
                                  </div>
                                </div> */}
                                <MainSlider wallets={wallets} />
                                <div
                                  class="Section_section__stusW Section_sectionLowerLayer__fiiyt">
                                  <div class="TransactionsHeader_container__I2CTr"
                                  // style="opacity: 1;"
                                  >
                                    <h3 class="np-text-title-subsection">Transactions</h3>
                                    {/* <a
                                      class="TransactionsHeader_buttonContainer__5PPpw"
                                      href="/all-transactions"><button
                                        class="btn btn-sm np-btn np-btn-sm btn-accent btn-priority-3"
                                        aria-disabled="false" type="button" aria-live="off"
                                        aria-busy="false">View all</button></a> */}
                                  </div>
                                  <div class="">

                                    {(transactions.list && transactions.list.length > 0) ? (
                                      transactions.list.slice(0, 10).map((item, index) => (
                                        <>
                                          <Transaction transaction={item} />
                                          <br />
                                        </>
                                      ))
                                    ) : (
                                      <Empty />
                                    )
                                    }
                                    {/* { transactions.list ?? <Empty/> : (transactions.list || []).map((item, index) => (
                                          <>
                                            <Transaction key={index} transaction={item} />
                                            <br />
                                          </>
                                        ))
                                    } */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <CryptoChart chartsList={chartsList} wallets={wallets} charts={charts} />
                            {/* <div
                            //  style="height:auto"
                            >
                              <div class="Section_section__stusW">
                                <div class="m-t-2">
                                  <div>
                                    <div class="Title_textAndIconContainer__zkn9Y">
                                      <h3 class="np-text-title-subsection">Recent contacts
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="Section_section__stusW Section_sectionLowerLayer__fiiyt">
                                  <div class="Section_horizontalScrollSection__5Iu1T"><a
                                    class="LabeledAvatar_container__J1fx1"
                                    href="/link?urn=urn%3Awise%3Acontacts%3A0d0bd74d-d85f-41c9-6841-e3ca99ddbe91"
                                    aria-label="Send money to or request money from your contact Raif">
                                    <div
                                      class="tw-badge tw-badge-border-light tw-badge-md LabeledAvatar_avatar__FJ4Ti">
                                      <div class="tw-badge__children">
                                        <div
                                          class="tw-avatar LabeledAvatar_avatar__FJ4Ti tw-avatar--72 tw-avatar--initials np-text-title-body">
                                          <div class="tw-avatar__content">R</div>
                                        </div>
                                      </div>
                                      <div class="tw-badge__content"><img
                                        src="https://wise.com/web-art/assets/flags/144/usd.png"
                                        class="LabeledAvatar_badge__VnKTK" /></div>
                                    </div>
                                    <div
                                      class="np-text-body-default LabeledAvatar_name__NH6EW">
                                      Raif</div>
                                  </a></div>
                                </div>
                              </div>
                            </div>
                            <div
                            // style="height:auto"
                            >
                              <div class="Section_section__stusW">
                                <div class="m-t-2">
                                  <div>
                                    <div class="Title_textAndIconContainer__zkn9Y">
                                      <h3 class="np-text-title-subsection">Розширте можливості
                                        з Wise</h3>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="Section_section__stusW Section_sectionLowerLayer__fiiyt">
                                  <div
                                  // style="transform:none"
                                  >
                                    <div class="Carousel_container">
                                      <div
                                      // style="opacity: 1;"
                                      ><button type="button"
                                        class="np-action-btn np-text-body-default-bold btn-priority-1 Carousel_chevronButton Carousel_prev"
                                        tabindex="-1"><span
                                          class="tw-icon tw-icon-chevron-up tw-chevron chevron-color left"
                                          aria-hidden="true" role="none"
                                          data-testid="chevron-up-icon"><svg
                                            width="24" height="24"
                                            fill="currentColor" focusable="false"
                                            viewBox="0 0 24 24">
                                              <path
                                                d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z">
                                              </path>
                                            </svg></span></button></div>
                                      <div class="Carousel_carousel">
                                        <div class="Card_cardDismissibleWrap__K617_"
                                          data-testid="DismissibleContainer"><a
                                            class="Card_launchpadCard__iBKP8"
                                            href="/link?urn=urn%3Awise%3Atransfers%3Ascheduled%3F%3Daction%3Dcreate"
                                          // style="background-color:#FFEB69FF"
                                          >
                                            <h4
                                              class="np-text-title-body Card_text__anrF9">
                                              Планування графіку переказів</h4><img
                                              alt="" class="Card_illustration__yMTqy"
                                              loading="eager"
                                              srcset="https://wise.com/web-art/assets/illustrations/calendar-small@1x.png 1x, https://wise.com/web-art/assets/illustrations/calendar-small@2x.png 2x, https://wise.com/web-art/assets/illustrations/calendar-small@3x.png 3x, https://wise.com/web-art/assets/illustrations/calendar-small@4x.png 4x"
                                              src="https://wise.com/web-art/assets/illustrations/calendar-medium@2x.png" />
                                          </a>
                                          <div
                                            class="Card_cardDismissibleButtonContainer__ZxlMu">
                                            <button type="button"
                                              class="Card_cardDismissCircle__MbuAq"
                                              aria-label="Dismiss &quot;Планування графіку переказів&quot;"
                                              tabindex="0"><span
                                                class="tw-icon tw-icon-cross "
                                                aria-hidden="true" role="none"
                                                data-testid="cross-icon"><svg
                                                  width="16" height="16"
                                                  fill="currentColor"
                                                  focusable="false"
                                                  viewBox="0 0 24 24">
                                                  <path
                                                    d="m19.629 5.915-1.2-1.2-6.257 6.257-6.258-6.257-1.2 1.2 6.258 6.257-6.258 6.257 1.2 1.2 6.258-6.257 6.257 6.257 1.2-1.2-6.258-6.257 6.258-6.257Z">
                                                  </path>
                                                </svg></span></button>
                                          </div>
                                        </div>
                                        <div class="Card_cardDismissibleWrap__K617_"
                                          data-testid="DismissibleContainer"><a
                                            class="Card_launchpadCard__iBKP8"
                                            href="/link?urn=urn%3Awise%3Adirect-debits%3F%3Daction%3Dsetup"
                                          // style="background-color:#FFEB69FF"
                                          >
                                            <h4
                                              class="np-text-title-body Card_text__anrF9">
                                              Готуйте прямі дебетування</h4><img
                                              alt="" class="Card_illustration__yMTqy"
                                              loading="eager"
                                              srcset="https://wise.com/web-art/assets/illustrations/plane-2-small@1x.png 1x, https://wise.com/web-art/assets/illustrations/plane-2-small@2x.png 2x, https://wise.com/web-art/assets/illustrations/plane-2-small@3x.png 3x, https://wise.com/web-art/assets/illustrations/plane-2-small@4x.png 4x"
                                              src="https://wise.com/web-art/assets/illustrations/plane-2-medium@2x.png" />
                                          </a>
                                          <div
                                            class="Card_cardDismissibleButtonContainer__ZxlMu">
                                            <button type="button"
                                              class="Card_cardDismissCircle__MbuAq"
                                              aria-label="Dismiss &quot;Готуйте прямі дебетування&quot;"
                                              tabindex="0"><span
                                                class="tw-icon tw-icon-cross "
                                                aria-hidden="true" role="none"
                                                data-testid="cross-icon"><svg
                                                  width="16" height="16"
                                                  fill="currentColor"
                                                  focusable="false"
                                                  viewBox="0 0 24 24">
                                                  <path
                                                    d="m19.629 5.915-1.2-1.2-6.257 6.257-6.258-6.257-1.2 1.2 6.258 6.257-6.258 6.257 1.2 1.2 6.258-6.257 6.257 6.257 1.2-1.2-6.258-6.257 6.258-6.257Z">
                                                  </path>
                                                </svg></span></button>
                                          </div>
                                        </div>
                                        <div class="Card_cardDismissibleWrap__K617_"
                                          data-testid="DismissibleContainer"><a
                                            class="Card_launchpadCard__iBKP8"
                                            href="/link?urn=urn%3Awise%3Aauto-conversions%3F%3Daction%3Dcreate"
                                          // style="background-color:#FFEB69FF"
                                          >
                                            <h4
                                              class="np-text-title-body Card_text__anrF9">
                                              Автоматична конвертація</h4><img alt=""
                                                class="Card_illustration__yMTqy"
                                                loading="eager"
                                                srcset="https://wise.com/web-art/assets/illustrations/convert-small@1x.png 1x, https://wise.com/web-art/assets/illustrations/convert-small@2x.png 2x, https://wise.com/web-art/assets/illustrations/convert-small@3x.png 3x, https://wise.com/web-art/assets/illustrations/convert-small@4x.png 4x"
                                                src="https://wise.com/web-art/assets/illustrations/convert-medium@2x.png" />
                                          </a>
                                          <div
                                            class="Card_cardDismissibleButtonContainer__ZxlMu">
                                            <button type="button"
                                              class="Card_cardDismissCircle__MbuAq"
                                              aria-label="Dismiss &quot;Автоматична конвертація&quot;"
                                              tabindex="0"><span
                                                class="tw-icon tw-icon-cross "
                                                aria-hidden="true" role="none"
                                                data-testid="cross-icon"><svg
                                                  width="16" height="16"
                                                  fill="currentColor"
                                                  focusable="false"
                                                  viewBox="0 0 24 24">
                                                  <path
                                                    d="m19.629 5.915-1.2-1.2-6.257 6.257-6.258-6.257-1.2 1.2 6.258 6.257-6.258 6.257 1.2 1.2 6.258-6.257 6.257 6.257 1.2-1.2-6.258-6.257 6.258-6.257Z">
                                                  </path>
                                                </svg></span></button>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                      // style="opacity: 1;"
                                      ><button type="button"
                                        class="np-action-btn np-text-body-default-bold btn-priority-1 Carousel_chevronButton Carousel_next"
                                        tabindex="-1"><span
                                          class="tw-icon tw-icon-chevron-up tw-chevron chevron-color right"
                                          aria-hidden="true" role="none"
                                          data-testid="chevron-up-icon"><svg
                                            width="24" height="24"
                                            fill="currentColor" focusable="false"
                                            viewBox="0 0 24 24">
                                              <path
                                                d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z">
                                              </path>
                                            </svg></span></button></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }


};

const widthConnect = connect(
  (state) => ({ ...state }),
  (dispatch) => bindActionCreators({ chartsList, walletsTypesAll }, dispatch)
);

export default widthConnect(HomePage);