import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { walletsTypesAll } from '../../actions/wallets';
import Avatar from '@mui/material/Avatar';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
// import { openWallet } from '../../api/walletsApi';
import { openBalance } from './../../actions/wallets'
import { useNavigate } from 'react-router-dom';
import TopBar from '../../components/topbar';


const OpenBalance = ({ wallets, walletsTypesAll, openBalance }) => {
    const [walletId, setWalletId] = useState(null);
    const navigate = useNavigate();

    const handleWalletChange = (event, newValue) => {
        // console.log(event, newValue.id);
        setWalletId(newValue.id); // Сохраняем выбранное значение в state
    };

    useEffect(() => {
        walletsTypesAll()
    }, [])

    const handleClick = walletId => {
        openBalance(walletId);
        navigate('/home')
    };
    const walletOpened = (wallets.wallets || []).map(el => el.wallet_type?.id);
    console.log(walletOpened);
    const walletIsNeedOpen = (wallets.walletsTypesAll || []).filter(wallet => !walletOpened.includes(wallet.id));

    if (!wallets.loading) {
        return (
            <div className='loader-block'>
                <div class="w-auto m-y-3 text-xs-center">
                    <div class="d-inline-flex">
                        <div class="tw-loader tw-loader--sm tw-loader--visible" data-testid="loader">

                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div id="__next">
                <div>
                    <div class="np-theme-personal">
                        <div class="np-theme-personal">
                            <div style={{ minHeight: '100vh' }}>
                                <TopBar size='small' />
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div class="p-x-2 p-y-5">
                                        <h2 class="text-xs-center m-b-5">Open a balance</h2>
                                        <div style={{ maxWidth: '400px', margin: 'auto' }}>
                                            <div class="form-group" data-testid="currency-select">
                                                <div>
                                                    <Autocomplete
                                                        className='btn btn-md np-btn np-btn-md btn-block np-btn-block np-dropdown-toggle np-text-body-large'
                                                        disablePortal
                                                        onChange={handleWalletChange}
                                                        options={walletIsNeedOpen}
                                                        getOptionLabel={(option) => option.code}
                                                        sx={{ width: '100%', borderRadius: '10px', margin: 0, padding: 0, }}
                                                        renderOption={(props, option) => (
                                                            <li {...props}>
                                                                {/* Добавляем изображение перед текстом */}
                                                                <Avatar
                                                                    src={option.img}
                                                                    alt={option.code}
                                                                    sx={{ marginRight: 1 }} // Добавляем отступ
                                                                />
                                                                {option.code}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => <TextField {...params} label="Choose currency..." />}
                                                    />
                                                </div>
                                            </div>
                                            <div class="text-xs-center m-b-4" data-testid="message">You can open balances in 40+ currencies.</div>
                                            <div class="m-t-4">
                                                <button onClick={() => handleClick(walletId)} class="btn btn-md np-btn np-btn-md btn-block np-btn-block btn-accent btn-priority-1" aria-disabled="false" type="button" aria-live="off" aria-busy="false">Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};


const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ walletsTypesAll, openBalance }, dispatch)
);

export default widthConnect(OpenBalance);