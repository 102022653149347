


export const Transaction = ({ transaction }) => {
    console.log(transaction);
    function hide_address(address) {
        if (!address) return 'WiseBit';
        return `${address.slice(0, 7)}...${address.slice(-7)}`
    }
    return (
        <a class="ActivitySummaryV2_activitySummary"
            role="button"
            href="#"
            // style={ transaction.status === 'WAIT' ? { backgroundColor: 'rgb(247 147 26 / 30%)' } : transaction.type === 'INCOME' ? { backgroundColor: 'rgb(159 232 112 / 30%)' } : { backgroundColor: 'rgba(255, 0, 0, 0.25)' }}

            style={
                transaction.status === 'FREEZE' || transaction.status === 'UNFREEZE' ?
                    { backgroundColor: 'rgb(0 167 255 / 30%)' }
                    : transaction.status === 'WAIT' ?
                        { backgroundColor: 'rgb(247 147 26 / 30%)' }
                        : transaction.type === 'INCOME' ?
                            { backgroundColor: 'rgb(159 232 112 / 30%)' }
                            : { backgroundColor: 'rgba(255, 0, 0, 0.25)' }

            }
            aria-labelledby=":R3tsuilvej6H1: :R3tsuilvej6: :R3tsuilvej6H2:">
            <div

                class="tw-avatar ActivitySummaryV2_avatar tw-avatar--48 tw-avatar--icon">
                <div class="tw-avatar__content"
                    style={{ backgroundImage: `url(${transaction?.wallet_to?.wallet_type?.img || transaction?.wallet_from?.wallet_type?.img})` }}
                // style="background-color:var(--color-background-neutral)"
                >
                    <div
                    // style="color:var(--color-content-primary)"
                    >
                        <span class="tw-icon tw-icon-groceries "
                            aria-hidden="true" role="none"
                            data-testid="groceries-icon">
                        </span>
                    </div>
                </div>
            </div>
            <div class="ActivitySummaryV2_summaryBoxBase ActivitySummaryV2_summaryBoxLeft"
                id=":R3tsuilvej6:">
                <div>
                    <p
                        class="np-text-body-large-bold ActivitySummaryV2_titleColor ActivitySummaryV2_titleBase ActivitySummaryV2_activitySummaryTitle m-b-0">
                        <strong>
                            {
                                transaction?.type === 'OUTCOME' ?

                                    transaction.status === 'FREEZE' ?
                                        `Freeze: ${hide_address(transaction?.wallet_to?.hash || transaction?.wallet_to_hash)}`
                                        :
                                        `To: ${hide_address(transaction?.wallet_to?.hash || transaction?.wallet_to_hash)}`
                                    :

                                    transaction.status === 'UNFREEZE' ?
                                        `Unfreeze: ${hide_address(transaction?.wallet_to?.hash || transaction?.wallet_to_hash)}`
                                        :
                                        `From: ${hide_address(transaction?.wallet_to?.hash || transaction?.wallet_to_hash)}`
                            }

                        </strong>
                    </p>
                    <p
                        class="np-text-body-default ActivitySummaryV2_otherColor ActivitySummaryV2_descriptionBase ActivitySummaryV2_activitySummaryDescription m-b-0">
                        {transaction?.date_string}</p>
                </div>
            </div>
            <div
                class="ActivitySummaryV2_summaryBoxBase ActivitySummaryV2_summaryBoxRight">
                <div
                    class="ActivitySummaryV2_summaryAccountWrapper">
                    <p id=":R3tsuilvej6H1:"
                        class="np-text-body-large-bold ActivitySummaryV2_titleBase ActivitySummaryV2_summaryAmount ActivitySummaryV2_noneColor m-b-0">
                        <span
                            class="parseMarkupTags_sensitive">{transaction?.type === 'OUTCOME' ? "-" : "+"}{transaction.amount} </span>
                        {transaction?.wallet_to?.wallet_type?.code || transaction?.wallet_from?.wallet_type?.code}
                    </p>
                    <p
                        class="np-text-body-default ActivitySummaryV2_descriptionBase ActivitySummaryV2_amountInfo ActivitySummaryV2_otherColor m-b-0">
                        <span
                            class="parseMarkupTags_sensitive">{transaction.amount_usd} </span>
                        USD
                    </p>
                </div>
            </div>
        </a>
    )
}