import { useEffect } from 'react';

const ForgetPasswordPage = () => {
  

    return (
      <>
        <h1>ForgetPassword</h1>
      </>
    );
};

export default ForgetPasswordPage;
