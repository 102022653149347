import { Routes, Route, Navigate } from 'react-router-dom';

import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import Singl from '../pages/Singl';
import Convert from '../pages/Convert';
import OpenBalance from '../pages/OpenBalance'
import Receive from '../pages/Receive';
import Mnemonic from '../pages/Mnemonic';
import Send from '../pages/Send';
import TestCharts from '../pages/TestCharts';

import Login from '../pages/Login';
import Register from '../pages/Register';
import ForgetPassword from '../pages/ForgetPassword';
import ResetPassword from '../pages/ResetPassword';

import Landing from '../pages/Landing';

import ProtectedRoute from './ProtectedRoute';
import ProtectedLoginRoute from './ProtectedLoginRoute';
import Referal from '../pages/Referal';
import Launchpool from '../pages/Launchpool';
import Freeze from '../pages/Launchpool/freeze';

export default function RootRoute({ isAuthenticated, setIsLoggedIn }) {

  return (
    <Routes>

      <>
        {/* <ProtectedRoute element={<Home />} path="/home" isAuthenticated={isAuthenticated} /> */}


        <Route element={<Landing />} path="/" />

        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Home />} />} path="/home" />
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Singl />} />} path="/balances/:id" />
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Convert />} />} path="/convert" />
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<OpenBalance />} />} path='/open-balance' />
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Receive />} />} path="/flows/balances/:id" />
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Send />} />} path="/send/:id" />
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Referal />} />} path="/invite" />
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<TestCharts />} />} path="/obsecharts" />
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Launchpool />} />} path="/launchpool" />
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Freeze />} />} path="/freeze/:id" />


        <Route path="/mnemonic" element={<Mnemonic />} />
        <Route element={<ProtectedLoginRoute isAuthenticated={isAuthenticated} element={<Login setIsLoggedIn={setIsLoggedIn} />} />} path="/login" />
        <Route element={<ProtectedLoginRoute isAuthenticated={isAuthenticated} element={<Register />} />} path="/register" />
        <Route element={<ProtectedLoginRoute isAuthenticated={isAuthenticated} element={<ForgetPassword />} />} path="/forgetpassword" />
        <Route element={<ProtectedLoginRoute isAuthenticated={isAuthenticated} element={<ResetPassword />} />} path="/resetpassword/:userId/:resetToken" />

        <Route path="*" element={<NotFound />} />
      </>
    </Routes>
  );
}
