import React, { useEffect, useRef, useState } from 'react';
import { ChartC } from './chart'
import { Select, FormControl, MenuItem } from '@mui/material';
import Avatar from '@mui/material/Avatar';

export const CryptoChart = ({ charts, wallets, chartsList }) => {

  const [selectedCurrency1, setSelectedCurrency1] = useState('');
  useEffect(() => {
    if (wallets?.walletsTypesAll?.length > 0 && wallets?.wallets) {
      setSelectedCurrency1(wallets?.wallets[0]?.wallet_type?.code)
      chartsList(wallets?.wallets[0]?.wallet_type?.code)
    }
  }, [wallets.walletsTypesAll, wallets.wallets])

  const handleCurrencyChange1 = (event) => {

    setSelectedCurrency1(event.target.value);
    chartsList(event.target.value)
    // let summ = +amount1 * +wallets.walletsTypesAll.find(currency => currency.code === selectedCurrency2).price / +wallets.walletsTypesAll.find(currency => currency.code === event.target.value).price
    // setAmount2(summ);

    // setWalletQuery({
    //   ...walletQuery,
    //   wallet_from_id: wallets.walletsTypesAll.find(currency => currency.code === event.target.value).id,
    //   wallet_to_id: wallets.walletsTypesAll.find(currency => currency.code === selectedCurrency2).id,
    // })
  };
  // console.log(charts.charts);
  return (
    <div
    // style="height:auto"
    >
      <div class="Section_section__stusW">
        <div class="TransferCalculator_transferCalculator__DS8FV">
          <div className='headeChart'>
            <h3 class="np-text-title-subsection TransferCalculator_title__zclFu">
              1 {selectedCurrency1} = {charts?.list?.[charts?.list.length - 1]?.close} USDT
            </h3>
            <span class="np-input-addon np-input-addon--placement-end np-input-addon--interactive">
              <fieldset
                class="CurrencyInput_currencyInput__vr0vT CurrencyInput_currencySelect__dP5XH np-input-group">
                <button
                  class="ButtonInput_buttonInput__FLXXU np-form-control np-form-control--size-md np-button-input"
                  role="combobox"
                  aria-label="Choose a currency to send."
                  aria-haspopup="dialog"
                  aria-expanded="false"
                  data-headlessui-state=""
                  type="button">
                  <span class="np-select-input-content">
                    <div
                      class="np-select-input-option-content-container">
                      {/* <div
                        class="np-select-input-option-content-icon">
                        <img class="wds-flag wds-flag-usd"
                          src="https://wise.com/web-art/assets/flags/usd.svg"
                          alt="" width="24"
                          height="24" />
                      </div> */}
                      {/* <div class="np-select-input-option-content-text">
                        <div class="np-select-input-option-content-text-line-1 np-select-input-option-content-text-within-trigger">
                          <h4 class="np-select-input-option-content-text-primary d-inline">
                            USD1
                          </h4>
                        </div>
                      </div> */}
                      <Select
                        className='NOBORDER'
                        labelId="currency-label"
                        value={selectedCurrency1}
                        onChange={handleCurrencyChange1}
                        label="Currency"
                        sx={{
                          width: '150px', height: '54px',
                          '.MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'red', // Обычное состояние рамки
                            },
                            '&:hover fieldset': {
                              borderColor: 'red', // Цвет рамки при наведении
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'red', // Цвет рамки при фокусе
                            },
                            '&.Mui-error fieldset': {
                              borderColor: 'red', // Цвет рамки при ошибке
                            },
                          },
                        }}>
                        {(wallets?.walletsTypesAll || []).sort((a,b) => a.id - b.id)?.map((currency) => (
                          <MenuItem
                            key={currency.code}
                            value={currency.code}
                          >
                            {/* <FlagIcon code={currency.flag} size={24} style={{ marginRight: 8 }} /> */}
                            <Avatar
                              src={currency.img}
                              alt={currency.code}
                              sx={{ marginRight: 1, width: '20px', height: '20px' }} // Добавляем отступ
                            />
                            {currency.code}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </span>
                </button>
                {/* <span
                  class="np-input-addon np-input-addon--placement-end np-input-addon--padding-sm"><span
                    class="np-select-input-addon-container"><span
                      class="np-select-input-addon CurrencySelect_chevron__upchr"><span
                        class="tw-icon tw-icon-chevron-down "
                        aria-hidden="true"
                        role="none"
                        data-testid="chevron-down-icon"><svg
                          width="16" height="16"
                          fill="currentColor"
                          focusable="false"
                          viewBox="0 0 24 24">
                          <path
                            d="M20.143 6.857 12.17 14.83 4.2 6.857 3 8.057l8.571 8.572a.846.846 0 0 0 .6.257.846.846 0 0 0 .6-.257l8.572-8.572-1.2-1.2Z">
                          </path>
                        </svg></span></span></span></span> */}
              </fieldset>
            </span>

          </div>
          {/* {JSON.stringify(charts?.list)} */}
          <div class="TransferCalculator_chart__Qi6Y8">
            <ChartC chartsList={charts?.list || null} rawData={{
              "Response": "Success",
              "Message": "",
              "HasWarning": false,
              "Type": 100,
              "RateLimit": {},
              "Data": {
                "Aggregated": false,
                "TimeFrom": 1725343200,
                "TimeTo": 1725379200,
                "Data": charts?.list || []
              },

            }} />
          </div>

        </div>
        {/*<div data-testid="navigation-option-component"
          class="NavigationOptionComponent_navigationOptionWrapper__Qc5bx">
          <button
            class="np-option np-navigation-option decision np-option__sm-media">
            <div class="media">
              <div class="media-left">
                <div class="np-option__no-media-circle">
                  <div
                    class="tw-avatar tw-avatar--48 tw-avatar--icon">
                    <div class="tw-avatar__content"
                    // style="background-color:var(--color-background-neutral)"
                    >
                      <div
                      // style="color:var(--color-content-primary)"
                      >
                        <span
                          class="tw-icon tw-icon-notification-active "
                          aria-hidden="true" role="none"
                          data-testid="notification-active-icon"><svg
                            width="24" height="24"
                            fill="currentColor"
                            focusable="false"
                            viewBox="0 0 24 24">
                            <path fill-rule="evenodd"
                              d="M5.443 1.713 6.557 3.04a8.547 8.547 0 0 0-3.128 6.6H1.714c0-3.085 1.372-5.957 3.729-7.928ZM20.572 9.64h1.714c0-3.085-1.372-5.957-3.729-7.928L17.443 3.04a8.547 8.547 0 0 1 3.129 6.6Zm0 6.001c-1.543 0-1.715-1.2-1.715-1.714V9.642c0-3.771-3.085-6.857-6.857-6.857-3.771 0-6.857 3.086-6.857 6.857v4.286c0 .514-.171 1.714-1.714 1.714a.86.86 0 0 0-.857.857v2.572a.86.86 0 0 0 .857.857h6A2.579 2.579 0 0 0 12 22.499a2.579 2.579 0 0 0 2.572-2.571h6a.86.86 0 0 0 .857-.857v-2.572a.86.86 0 0 0-.857-.857ZM12 20.785a.86.86 0 0 1-.857-.857h1.714a.86.86 0 0 1-.857.857Zm7.714-2.571H4.286v-.943c1.757-.343 2.571-1.843 2.571-3.343V9.642C6.857 6.814 9.172 4.5 12 4.5c2.829 0 5.143 2.315 5.143 5.143v4.286c0 1.5.814 3 2.571 3.343v.943Z"
                              clip-rule="evenodd">
                            </path>
                          </svg></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="media-body">
                <h4
                  class="np-text-body-large-bold text-primary np-option__title">
                  Get exchange rate updates</h4>
              </div>
              <div class="media-right"><span
                class="tw-icon tw-icon-chevron-up tw-chevron chevron-color right d-block"
                aria-hidden="true" role="none"
                data-testid="chevron-up-icon"><svg width="16"
                  height="16" fill="currentColor"
                  focusable="false" viewBox="0 0 24 24">
                  <path
                    d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z">
                  </path>
                </svg></span></div>
            </div>
          </button>
        </div>
        <div data-testid="navigation-option-component"
          class="NavigationOptionComponent_navigationOptionWrapper__Qc5bx">
          <button
            class="np-option np-navigation-option decision np-option__sm-media">
            <div class="media">
              <div class="media-left">
                <div class="np-option__no-media-circle">
                  <div
                    class="tw-avatar tw-avatar--48 tw-avatar--icon">
                    <div class="tw-avatar__content"
                    // style="background-color:var(--color-background-neutral)"
                    >
                      <div
                      // style="color:var(--color-content-primary)"
                      >
                        <span
                          class="tw-icon tw-icon-speech-bubble-message "
                          aria-hidden="true" role="none"
                          data-testid="speech-bubble-message-icon"><svg
                            width="24" height="24"
                            fill="currentColor"
                            focusable="false"
                            viewBox="0 0 24 24">
                            <path fill-rule="evenodd"
                              d="M21.429 3H2.572a.86.86 0 0 0-.858.857v12.857a.86.86 0 0 0 .857.857h4.286v2.572c0 .3.172.6.472.771.128.086.257.086.385.086a.864.864 0 0 0 .472-.129l4.928-3.3h8.315a.86.86 0 0 0 .857-.857V3.857A.86.86 0 0 0 21.429 3Zm-.857 12.857h-7.715a.864.864 0 0 0-.471.129l-3.814 2.571v-1.843a.86.86 0 0 0-.858-.857H3.43V4.714H20.57v11.143Zm-1.715-8.574H5.143v1.714h13.714V7.283Zm-13.714 4.29h13.714v1.714H5.143v-1.715Z"
                              clip-rule="evenodd">
                            </path>
                          </svg></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="media-body">
                <h4
                  class="np-text-body-large-bold text-primary np-option__title">
                  Give us feedback</h4>
              </div>
              <div class="media-right"><span
                class="tw-icon tw-icon-chevron-up tw-chevron chevron-color right d-block"
                aria-hidden="true" role="none"
                data-testid="chevron-up-icon"><svg width="16"
                  height="16" fill="currentColor"
                  focusable="false" viewBox="0 0 24 24">
                  <path
                    d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z">
                  </path>
                </svg></span></div>
            </div>
          </button>
          </div>*/}
      </div>
    </div>
  );
};

