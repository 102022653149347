import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MainSlider from '../../components/mainslider'
import { Transaction } from '../../components/transactions';
import { CryptoChart } from '../../components/chart'
import { chartsList } from './../../actions/charts'
import { walletsTypesAll } from './../../actions/wallets'
import TopBar from '../../components/topbar'
import { SideBar } from '../../components/sidebar'
import './styles.css'; // Импорт CSS
import { launchpoolsList } from '../../actions/launchpools';
import launchpools from '../../reducers/launchpools';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'antd';


const moment = require('moment');


function formatBalance(balance) {
    if (balance >= 1000000000000) { // 1 триллион и выше
        return Math.floor(balance / 1000000000000) + 'B+';
    } else if (balance >= 1000000000) { // 1 миллиард и выше
        return Math.floor(balance / 1000000000) + 'B+';
    } else if (balance >= 10000000) { // 10 миллионов и выше
        return Math.floor(balance / 1000000) + 'М+';
    } else if (balance >= 1000000) { // 1 миллион
        return '1М+';
    } else if (balance >= 100000) { // 100 тысяч и выше
        return Math.floor(balance / 1000) + 'к+';
    } else if (balance >= 10000) { // 10 тысяч и выше
        return Math.floor(balance / 1000) + 'к+';
    } else if (balance >= 1000) { // 1 тысяча
        return '1к+';
    } else {
        return balance.toString(); // если меньше 1000, выводим как есть
    }
}


const Launchpool = ({ customer, wallets, chartsList, charts, transactions, walletsTypesAll, launchpoolsList, launchpools }) => {
    const navigate = useNavigate();
    const [isOpenBurger, setIsOpen] = useState(false);
    const toggleClass = () => {
        setIsOpen(!isOpenBurger);
    };

    useEffect(() => {
        if (customer.isAuthenticated) {
            walletsTypesAll()
            launchpoolsList()
        }
    }, [customer.isAuthenticated]);
 
    const addParamsToUrl = (data) => {
        const params = new URLSearchParams({ walletId: wallets.wallets.find(wallet => wallet.wallet_type.code === 'WISE').id });
        navigate(`/freeze/${data.id}?${params.toString()}`)
      };

    // console.log(customer.data.last_name, customer.data.first_name);
    if (!wallets.loading) {
        return (
            <div className='loader-block'>
                <div class="w-auto m-y-3 text-xs-center">
                    <div class="d-inline-flex">
                        <div class="tw-loader tw-loader--sm tw-loader--visible" data-testid="loader"></div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <>

                <div id="__next">
                    <div class="np-theme-personal">
                        <div class="activity-show-sensitive">
                            <div class="np-theme-personal">
                                <div class="page-layout">
                                    <div class="column-layout">
                                        <SideBar toggleClass={toggleClass} isOpenBurger={isOpenBurger} />
                                        <div class="column-layout-main">
                                            <TopBar customer={customer} toggleClass={toggleClass} />
                                            <div id="main" class="container-content">
                                                <div
                                                // style="opacity:1;pointer-events:all"
                                                >
                                                    <div class="Launchpad_launchpadComponentsContainer__jRN7N">
                                                        <div
                                                        // style="height:auto"
                                                        >
                                                            <div class="Section_section__stusW">
                                                                <div class="css-1shmh61">
                                                                    <img class="absolute right-0 bottom-0 hidden lg:block" width="618" height="328" src="images/lpd-banner.png" fetchpriority="high" />
                                                                    <div class="css-2ydkcp">
                                                                        <div class="css-1o8ti3k">
                                                                            <div class="css-1j6a75o">
                                                                                Wise Bit platform for token launches.
                                                                            </div>
                                                                            <div class="min-h-[30px] css-160cmni">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div class="css-1oyssjc">
                                                                        <div class="css-1maa655">
                                                                            <div class="css-63dn76">
                                                                                <div class="css-1nvnfl9">
                                                                                    $ 1 108 213 504
                                                                                </div>
                                                                                <div class="css-exa5nr">
                                                                                    Сейчас заблокировано
                                                                                </div>
                                                                            </div>
                                                                            <div class="css-63dn76">
                                                                                <div class="css-1nvnfl9">
                                                                                    $ 185 563 626
                                                                                </div>
                                                                                <div class="css-exa5nr">
                                                                                    Собрано средств
                                                                                </div>
                                                                            </div>
                                                                            <div class="css-63dn76">
                                                                                <div class="css-1nvnfl9">
                                                                                    98
                                                                                </div>
                                                                                <div class="css-exa5nr">
                                                                                    Запущенные проекты
                                                                                </div>
                                                                            </div>
                                                                            <div class="css-63dn76">
                                                                                <div class="css-1nvnfl9">
                                                                                    5 877 980
                                                                                </div>
                                                                                <div class="css-exa5nr">
                                                                                    Кол-во уникальных участников за все время
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                    <div style={{ margin: '30px 0 0 0' }}>
                                                                        <Alert message=
                                                                            "Launchpads are now available to everyone! This feature has been in beta testing on our service for a long time, and now we are excited to present it in its full version."
                                                                            type="warning" />

                                                                    </div>


                                                                </div>

                                                                <div>
                                                                    <div class="Title_textAndIconContainer__zkn9Y">
                                                                        <h1 class="np-text-title-screen">
                                                                            <span class="parseMarkupTags_sensitive">
                                                                                Launchpool
                                                                            </span>

                                                                        </h1>

                                                                    </div>
                                                                </div>
                                                                {/* !moment(data.date_start).add(data.durationDays, 'days').isBefore(moment()) */}
                                                                <div>
                                                                    {launchpools?.list?.map((data) =>
                                                                        <div class="pool_box" onClick={moment().isBetween(moment(data.date_start), moment(data.date_start).add(data.durationDays, 'days'), null, '[]') ? () => addParamsToUrl(data) : undefined}>
                                                                            <div class="left-section">
                                                                                <div className='left-section-header'>
                                                                                    <div
                                                                                        class="tw-avatar tw-avatar--48 tw-avatar--thumbnail">
                                                                                        <div class="tw-avatar__content">
                                                                                            <img
                                                                                                class="wds-flag wds-flag-usd"
                                                                                                src={data.img}
                                                                                                alt="" width="64"
                                                                                                height="64" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <h2>{data.name}</h2>
                                                                                    <div class="status">{data.status}</div>
                                                                                </div>
                                                                                <p>{data.description}</p>
                                                                                <div class="details">
                                                                                    <p>The total volume of the airdrop: <span>{data.total_coin_airdrop.toLocaleString('ru-RU')}</span></p>
                                                                                    <p>The duration of the project: <span>{data.durationDays} days</span></p>
                                                                                    <p>The project start date: <span>{moment(data.date_start).format('DD-MM-YYYY')}</span></p>
                                                                                    <p>The project end date: <span>{moment(data.date_end).format('DD-MM-YYYY')}</span></p>

                                                                                </div>
                                                                            </div>
                                                                            <div class="right-section">

                                                                                <div class="pool">
                                                                                    <div class="d-flex align-items-center">
                                                                                        <div aria-hidden="true" class="BalanceDetailsCard_balanceCardAvatar">
                                                                                            <div
                                                                                                class="tw-avatar tw-avatar--48 tw-avatar--thumbnail">
                                                                                                <div class="tw-avatar__content">
                                                                                                    <img
                                                                                                        class="wds-flag wds-flag-usd"
                                                                                                        src='https://raggededge.com/wp-content/uploads/2023/02/Tapestry-Flag-1080-1350-Crops.jpg'
                                                                                                        alt="" width="64"
                                                                                                        height="64" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <h4 class="np-text-title-body BalanceDetailsCard_balanceTextContentSecondary BalanceDetailsCard_balanceCardText BalanceDetailsCard_balanceTextContentSecondaryOverflow">
                                                                                            WISE Pool
                                                                                        </h4>
                                                                                    </div>

                                                                                    <div className='descript_pool_box'>Lock WISE, receive {data.name} airdrop</div>
                                                                                    <div class="stats">
                                                                                        <p><span>Participants:</span> <span>{data.customers_count}</span></p>
                                                                                        <p><span>Total locked:</span> <span>{formatBalance(data.amount_freeze)} WISE</span></p>
                                                                                        {data.my_amount_freeze ?
                                                                                            <p><span>My balance is frozen:</span> <span>{data.my_amount_freeze.toFixed(2)} WISE</span></p>
                                                                                            :
                                                                                            <></>
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }


};

const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ chartsList, walletsTypesAll, launchpoolsList }, dispatch)
);

export default widthConnect(Launchpool);