import { useEffect, useState, useRef } from 'react';
import { Carousel } from 'nuka-carousel';
import Item from './item';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';



const Mainslider = ({ wallets }) => {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef(null);
  const data = wallets?.wallets || [];

  return (
    <div
      class="Section_section__stusW Section_sectionLowerLayer__fiiyt">
      <div
      >
        <div>
          <div>
            {
              data && data.length ? (
                <>
                  <Swiper
                    // onInit={(swiper) => {
                    //   swiperRef.current = swiper;
                    // }}
                    // onSlideChange={(swiper) => {
                    //   setIsBeginning(swiper.isBeginning);
                    //   setIsEnd(swiper.isEnd);
                    // }}

                    onInit={(swiper) => {
                      swiperRef.current = swiper;
                    }}
                    onSlideChange={(swiper) => {
                      setIsBeginning(swiper.isBeginning);
                      setIsEnd(swiper.isEnd);
                    }}
                    onReachBeginning={() => setIsBeginning(true)}  // Когда достигнут первый слайд
                    onReachEnd={() => setIsEnd(true)}  // Когда достигнут последний слайд
                    onFromEdge={() => {  // Когда Swiper уходит с края
                      setIsBeginning(false);
                      setIsEnd(false);
                    }}
                    // slidesPerView={3.2}
                    spaceBetween={30}
                    className="mySwiper"
                    cssMode={true}
                    navigation={{
                      nextEl: '.custom-button-next',
                      prevEl: '.custom-button-prev',
                    }}
                    pagination={true}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Navigation, Mousewheel, Keyboard]}
                    slidesPerView={1}

                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      1024: {
                        slidesPerView: 3.2,
                      }
                    }}
                    style={{ overflow: 'visible' }}

                  >

                    {
                      data.map((item, index) => (
                        <SwiperSlide>

                          <Item key={item.id}
                            item={item}
                          />
                        </SwiperSlide>
                      ))
                    }
                    <SwiperSlide>
                      <a aria-label="Open a balance."
                        class="BalanceDetailsCard_balanceCard BalanceDetailsCard_balanceCardLight"
                        href="/open-balance"
                      // style="opacity:1"
                      >
                        <div class="d-flex align-items-center">
                          <div aria-hidden="true"
                            class="BalanceDetailsCard_balanceCardAvatar">
                            <div
                              class="tw-avatar tw-avatar--48 tw-avatar--icon">
                              <div class="tw-avatar__content"
                              // style="background-color:var(--color-background-neutral)"
                              >
                                <div
                                // style="color:var(--color-content-primary)"
                                >
                                  <span
                                    class="tw-icon tw-icon-plus "
                                    aria-hidden="true"
                                    role="none"
                                    data-testid="plus-icon"><svg
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                      focusable="false"
                                      viewBox="0 0 24 24">
                                      <path
                                        d="M22.286 11.143h-9.429V1.715h-1.714v9.428H1.714v1.715h9.429v9.428h1.714v-9.428h9.429v-1.715Z">
                                      </path>
                                    </svg></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="BalanceDetailsCard_balanceCardText">
                          <div
                            class="np-text-body-default d-flex align-items-center">
                            <span
                              class="BalanceDetailsCard_balanceTextContentSecondary">Add
                              another currency to your
                              account.</span>
                          </div>
                          <div
                            // style="font-size:1.375rem;letter-spacing:normal;overflow-wrap:normal;white-space:nowrap"
                            class="m-b-0 title-3 BalanceDetailsCard_balanceCardValue">
                          </div>
                        </div>
                      </a>
                    </SwiperSlide>

                  </Swiper>
                  <div style={{ zIndex: 999 }}
                    className={`nuka-nav-button nuka-nav-button-prev nuka-nav-button-enabled custom-button-prev ${isBeginning ? 'hidden' : ''}`}


                  ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentcolor"><path fill-rule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd"></path></svg></div>
                  <div style={{ zIndex: 999 }}
                    className={`nuka-nav-button nuka-nav-button-next nuka-nav-button-enabled custom-button-next ${isEnd ? 'hidden' : ''}`}

                  ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentcolor"><path fill-rule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd"></path></svg></div>


                  {/* <Carousel swiping={false} scrollDistance="screen" showArrows="always" keyboard={false}>
                    {
                      data.map((item, index) => (
                        // <div key={index}>{item}</div>
                        <Item key={item.id}
                          item={item}
                        />
                      ))
                    }

                    <a aria-label="Open a balance."
                      class="BalanceDetailsCard_balanceCard BalanceDetailsCard_balanceCardLight"
                      href="/open-balance"
                    // style="opacity:1"
                    >
                      <div class="d-flex align-items-center">
                        <div aria-hidden="true"
                          class="BalanceDetailsCard_balanceCardAvatar">
                          <div
                            class="tw-avatar tw-avatar--48 tw-avatar--icon">
                            <div class="tw-avatar__content"
                            // style="background-color:var(--color-background-neutral)"
                            >
                              <div
                              // style="color:var(--color-content-primary)"
                              >
                                <span
                                  class="tw-icon tw-icon-plus "
                                  aria-hidden="true"
                                  role="none"
                                  data-testid="plus-icon"><svg
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    focusable="false"
                                    viewBox="0 0 24 24">
                                    <path
                                      d="M22.286 11.143h-9.429V1.715h-1.714v9.428H1.714v1.715h9.429v9.428h1.714v-9.428h9.429v-1.715Z">
                                    </path>
                                  </svg></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="BalanceDetailsCard_balanceCardText">
                        <div
                          class="np-text-body-default d-flex align-items-center">
                          <span
                            class="BalanceDetailsCard_balanceTextContentSecondary">Add
                            another currency to your
                            account.</span>
                        </div>
                        <div
                          // style="font-size:1.375rem;letter-spacing:normal;overflow-wrap:normal;white-space:nowrap"
                          class="m-b-0 title-3 BalanceDetailsCard_balanceCardValue">
                        </div>
                      </div>
                    </a>
                  </Carousel> */}
                </>
              ) :
                <></>
            }

          </div>
        </div>
      </div>
    </div >
  );
};

export default Mainslider;









